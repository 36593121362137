<template>
  <div class="datarate_modal rate-font-family modal-search">
    <!-- Modal content -->
    <div class="modal-content new-popup">
      <div class="modal-heading d-block">
        <div class="position-relative">
          <!--        <img src="https://icon-library.com/images/info-icon-transparent/info-icon-transparent-5.jpg" width="18" height="18" class="cursor-pointer" alt="data-rate-media" :class="this.$vuetify.rtl ? 'info-icon' : 'info-icon-3'" @click="$store.state.info.model = true"/>-->
        </div>
        <Header @logout="logout" @getUserData="getUserData" @close="close" />
        <div class="d-flex heading-section justify-space-between">
          <div class="datarate-heading mt-5">
            <img :src="media_url + 'stars.svg'" alt="data-rate-media"/>
            <h4>{{ $t("message.datarate") }}</h4>
          </div>
        </div>
      </div>
      <!-- <stars></stars> -->
      <login-component class="main-content" v-if="showLoginForm" @show-search-form="setFormValue"/>
      <div class="main-content" v-if="!showLoginForm">
        <div class="modal-tag" style="margin-top: 1em">
          <v-combobox ref="searchBox" v-model="values" :menu-props="{value: false}" @click:append="addTags()"
                      append-icon="mdi-magnify" class="datarate-search-box" :items="items"
                      :label="$t('message.searchLabel')" deletable-chips multiple chips></v-combobox>
        </div>

        <h2 class="related-heading">{{ $t("message.relatedSearch") }}</h2>
        <v-row style="margin: 0px; margin-bottom: 12px;margin-top:20px">
          <v-chip @click="addChip(chip)" class="mr-2 mb-2" v-for="(chip, index) in defaultChips" :key="index">
            {{ chip }}
          </v-chip>
        </v-row>

        <div class="main-cards" v-if="results && results.length > 0">
          <SearchDetails @previous-rating="getRating($event)" v-for="(item, index) in results" :key="index"
                         :item="item"/>
        </div>

        <!--          todo- return this funcuinality when points reviews and comments is working -->

        <!--            <div class="tag-section" v-if="userData">-->
        <!--                <div class="points">-->
        <!--                    <img :src="media_url + 'points.png'" />-->
        <!--                    <div>-->
        <!--                        <h5>Points</h5>-->
        <!--                        &lt;!&ndash; <p>{{ user.points }}</p>   &ndash;&gt;-->
        <!--                        <p>{{ this.userData.points }}</p>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="">-->
        <!--                    <img :src="media_url + 'reviews.svg'" />-->
        <!--                    <div>-->
        <!--                        <h5>Reviews</h5>-->
        <!--                        &lt;!&ndash; <p>{{ user.reviews }}</p>   &ndash;&gt;-->
        <!--                        <p>{{ this.userData.reviews }}</p>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--                <div class="">-->
        <!--                    <img :src="media_url + 'comments.svg'" />-->
        <!--                    <div>-->
        <!--                        <h5>Comments</h5>-->
        <!--                        &lt;!&ndash; <p>{{ user.comments }}</p>   &ndash;&gt;-->
        <!--                        <p>{{ this.userData.comments }}</p>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->
      </div>
      <div class="modal-footer" v-if="!showLoginForm">
        <v-btn class="color-green" @click="ratingModel">
          <v-icon>mdi-star</v-icon>
          {{ $t("message.ratePage") }}
        </v-btn>
        <!-- <v-btn class="share">
            <img :src ="media_url + 'share.svg'" alt="" />
        </v-btn>
        <v-btn class="save">
            <img :src ="media_url + 'save.svg'" alt="" />
        </v-btn>
        <v-btn class="dangerous">
            <img :src ="media_url + 'danger.svg'" />
            <span class="my-auto"> Mark as Dangerous </span>
        </v-btn> -->
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "../../services/api";
import LoginComponent from "../LoginComponent/LoginComponent";
import SearchDetails from "./SearchDetails"
import Header from "../Shared/Header.vue";
// import {getElementByXpath} from "../../../public/helper"
import {
  base_url_media, unmarkBlue
} from "../../../public/helper"

export default {
  name: "SearchComponent",
  props: {
    showProfile: {
      type: Boolean,
      default: false,
    },
    currentLang: {
      type: String,
      default: "en",
    },
  },
  components: {
    LoginComponent,
    SearchDetails,
    Header
  },
  data: function LoginComponent() {
    return {
      previousRating: {
        cssPath: "",
        content: ""
      },
      media_url: base_url_media,
      redirectDomain: "",
      items: [],
      values: [],
      results: [],
      userData: null,
      defaultChips: [
        "instruction",
        "tutorial",
        "learning",
        "training",
        "tution",
        "online learning",
      ],
      showLoginForm: false,
      selectedText: "",
      css_path: "",
    };
  },
  methods: {
    getRating: function (data) {
      this.previousRating.cssPath = data.cssPath
      this.previousRating.content = data.content
    },
    addTags: function () {
      if (!!this.$refs.searchBox.lazySearch && this.values.indexOf(this.$refs.searchBox.lazySearch) === -1) {
        this.values.push(this.$refs.searchBox.lazySearch)
        this.$refs.searchBox.lazySearch = ''
      } else {
        this.$refs.searchBox.lazySearch = ''
      }
    },
    scrollToDiv: function (item) {
      document.querySelectorAll(".text-mark-bg").forEach(function (el) {
        el.classList.remove("text-mark-bg");
      });
      const xPathResult = document.evaluate(item, document);
      if (xPathResult) {
        const element = xPathResult.iterateNext();
        element.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
        element.classList.add("text-mark-bg");
      }
    },
    logout() {
      localStorage.removeItem("user_datarate");
      this.$store.state.user = null;
      this.showLoginForm = false;
      this.userData = null;
    },
    ratingModel() {
      this.$store.state.rating.model = false;
      this.$store.dispatch("ratingModel", {
        marked_text: document.getElementsByTagName("body")[0].innerText,
        csspath: this.css_path,
        object_id: "",
        whole_page: true,
        page_id: window.location,
        ratingModel: false,
      });
    },
    removeBack(e) {
      const className = e.target.className;
      if (!className.includes("text-mark-bg") && !className.includes("content-mark")) {
        document.querySelectorAll(".text-mark-bg").forEach(function (el) {
          el.classList.remove("text-mark-bg");
        });
      }

    },
    close: function () {
      this.$store.state.search.model = false;
      unmarkBlue(this.previousRating.cssPath, this.previousRating.content)
    },
    addChip: function (item) {
      if (
          this.values?.indexOf(item) === -1 ||
          this.items?.indexOf(item) === -1
      ) {
        this.items.push(item);
        this.values.push(item);
      }
    },

    setFormValue(item) {
      this.showLoginForm = item;
    },
    getUserData(item) {
      this.userData = item;
    },
  },
  watch: {
    values(val) {
      if (val?.length > 0) {
        let params = "";
        val.map((item, index) =>
            index === 0 ? (params = item) : (params += "," + item)
        );
        HTTP.get("/rates/search?tags=" + params).then((response) => {
          this.results = response.data.rates;
        });
      } else if (val?.length === 0) {
        this.results = [];
      }
    },

  },
  mounted() {
    this.redirectDomain = window.location.href;
    document.addEventListener('click', this.removeBack);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.removeBack);
  },
};
</script>

<style>
/* The Modal (background) */
.datarate_modal {
  position: fixed !important;
  /* Stay in place */
  z-index: 1 !important;
  /* Sit on top */
  /* Location of the box */
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  /* Full width */
  height: 100% !important;
  /* Full height */
  overflow: auto !important;
  /* Enable scroll if needed */
  /*background-color: rgb(0, 0, 0) !important; !* Fallback color *!*/
  /*background-color: rgba(0, 0, 0, 0.4); !* Black w/ opacity *!*/
  z-index: 9999999 !important;
  overflow-x: hidden !important;
}

.modal-search {
  padding-top: 0px !important;
}

/* Modal Content */
.datarate_modal .modal-content {
  padding: 24px 28px !important;
  border: 1px solid #888 !important;
  width: 420px !important;
  border-radius: 20px !important;
  box-shadow: 0px 5px 26px 4px rgba(0, 0, 0, 0.05),
  0px 7px 9px -4px rgba(0, 0, 0, 0.1) !important;
  border: none;
}

.v-input__icon > button {
  position: relative;
  transform: none !important;
}

.datarate_modal .modal-content.new-popup {
  padding: 24px 0px !important;
  width: 420px !important;
}

.main-cards {
  height: 250px !important;
  overflow-y: auto !important;
  padding-right: 6px !important;
}

.datarate_modal .modal-content.new-popup .main-content {
  padding: 0px 28px !important;
}

.datarate_modal .modal-content.new-popup .main-content .related-heading {
  font-size: 20px !important;
  font-weight: 500 !important;
  margin-top: 20px !important;
  margin-bottom: 0 !important;
  line-height: 0 !important;
}

/* .new-popup .modal-heading .main-section .img-section h4 {
    padding-right: 10px !important;
} */
.modal-heading .heading-section {
  justify-content: space-between !important;
}

.datarate_modal .modal-content.new-popup .main-content .tag-section p {
  margin-bottom: 0 !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

/* Card scroll */
.main-cards::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

.main-cards::-webkit-scrollbar-thumb {
  background-color: #f3f3f3 !important;
}

.yellow--text.text--darken-3 {
  color: #f9a825 !important;
  caret-color: #f9a825 !important;
}

.grey--text.text--darken-1 {
  color: #757575 !important;
  caret-color: #757575 !important;
}

.m-45 {
  margin-left: 45px;
}

/* The Close Button */
.datarate_close-4 {
  color: #aaaaaa !important;
  float: right !important;
  font-size: 28px !important;
  right: 14px !important;
  top: -25px !important;
  font-weight: bold !important;
  z-index: 1000 !important;
  position: absolute;
}

.datarate_close-he-4 {
  color: #aaaaaa !important;
  float: right !important;
  font-size: 28px !important;
  left: 14px !important;
  top: -25px !important;
  font-weight: bold !important;
  z-index: 1000 !important;
  position: absolute;
}

.datarate_close-4:hover,
.datarate_close-4:focus, .datarate_close-he-4:hover,
.datarate_close-he-4:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.datarate_modal .modal-content.new-popup .data-rate-draggable {
  visibility: hidden !important;
}

.datarate_modal .modal-content.new-popup:hover .data-rate-draggable {
  visibility: visible !important;
}

.d-none {
  display: none !important;
}

.text-mark-bg {
  background: lightgreen !important;
}

.datarate-search-box > div > div > div > div > input {
  border: none !important;
}

.new-popup .modal-heading {
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #262626 !important;
  /* display: flex !important; */
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: none !important;
  padding-left: 28px !important;
  padding-right: 28px !important;
  padding-bottom: 24px !important;
}

.new-popup .modal-heading .main-section .my-profile {
  display: none;
}

.new-popup .modal-heading .main-section .user-header {
  margin-bottom: 0 !important;
}


.new-popup .modal-heading .main-section .img-section h4 {
  font-size: 14px !important;
  padding-left: 0 !important;
}

.heading-section {
  justify-content: space-between !important;
  align-items: center !important;
}

.new-popup .modal-heading div {
  display: flex !important;
  align-items: center !important;
}

.new-popup .modal-heading h4 {
  padding-left: 10px !important;
  padding-bottom: 0px !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.new-popup .modal-footer {
  border-top: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-wrap: wrap !important;
  padding-left: 12px !important;
  padding-right: 12px !important;
  padding-top: 24px !important;
  margin-top: 24px !important;
}

.new-popup .modal-footer .v-btn {
  color: #939393 !important;
  background: #fff !important;
  box-shadow: unset !important;
  text-transform: inherit !important;
  padding: 0 !important;
  font-size: 12px !important;
  min-width: 20px !important;
}

.new-popup .modal-footer .dangerous {
  color: #939393 !important;
}

.new-popup .modal-footer .color-green {
  color: #00ca99 !important;
}

.modal-footer .color-green .v-icon {
  margin-right: 2px !important;
}

.modal-footer .share,
.modal-footer .save {
  min-width: 16px !important;
}

.modal-footer .share img,
.modal-footer .save img {
  margin-right: 4px !important;
}

/* .datarate-heading {
    margin-top: -18px;
} */

.datarate-heading h4 {
  margin: 0 !important;
  padding: 0 !important;
}

.datarate-heading img {
  margin: 0 !important;
  padding: 0 !important;
}

.main-section img {
  margin: 0 !important;
  padding: 0 !important;
}

.user-header .img-section h4 {
  margin: 0 !important;
  padding: 0 !important;
}

.rate-btn {
  margin-top: 1em !important;
  text-align: center !important;
}

.rate-btn .v-btn {
  background: #00ca99 !important;
  border-radius: 25px !important;
  padding: 1.1em 4em !important;
  border: none !important;
  cursor: pointer !important;
  color: white !important;
}

.movable {
  position: relative !important;
  top: unset !important;
  left: unset !important;
  height: 20px !important;
  width: 100% !important;
}

.modal-tags .tag-input input {
  line-height: 40px !important;
}

.modal-tags .tag-input .tag-input__tag {
  margin-top: 0 !important;
  padding: 4px 12px !important;
}

.ml-2 {
  margin-left: 10px;
}

.mr-2 {
  margin-right: 10px;
}

.mr-1 {
  margin-right: 4px;
}

.ml-1 {
  margin-left: 4px;
}

.mb-2 {
  margin-bottom: 10px;
}

.modal-tags .tag-input .tag-input__tag > span {
  opacity: 1 !important;
  margin-right: 6px !important;
}

.modal-tags .tag-input .tag-input__tag > span:last-child {
  margin-right: 0 !important;
}

.modal-tag .theme--light.v-text-field > .v-input__control > .v-input__slot:before {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

.modal-tag .v-text-field > .v-input__control > .v-input__slot:after {
  border-color: #00ca99 !important;
}

.new-popup .card-header {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
  margin-bottom: 5px !important;
}

.new-popup .card-header .title {
  font-size: 16px !important;
  color: #262626 !important;
  font-weight: bold !important;
  padding: 0 !important;
}

.new-popup .card-header a {
  color: #128d6f !important;
  font-size: 12px !important;
  font-weight: bold !important;
}

.new-popup .v-card {
  box-shadow: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 10px !important;
  padding: 24px 24px 5px 24px !important;
  margin-bottom: 8px !important;
}

.new-popup .v-card .mdi-star::before {
  color: #ffcc48 !important;
}

.mdi-star-outline::before {
  color: lightgray !important;
  content: "\F04CE" !important;
}

.new-popup {
  position: absolute !important;
  right: 0 !important;
}

.new-popup .v-card .rating-row {
  margin-bottom: 10px !important;
}

.new-popup .v-card .v-rating .v-icon {
  padding: 1px 2px !important;
  font-size: 20px !important;
}

.new-popup .v-card .v-rating .v-icon:last-child {
  margin-right: 9px !important;
}

.new-popup .v-card .grey--text {
  color: #262626 !important;
  font-size: 18px !important;
  font-weight: bold !important;
}

.new-popup .v-card .grey--text span {
  color: rgba(38, 38, 38, 0.5) !important;
  font-size: 12px !important;
  font-weight: normal !important;
}

.new-popup .v-card .grey--text .based-on {
  margin-left: 14px !important;
}

.new-popup .v-card .rating-section {
  padding: 10px !important;
  padding-top: 15px !important;
  padding-bottom: 0px !important;
}

.v-chip {
  background-color: #f3f3f3 !important;
}

.new-popup .v-card .v-chip-group .v-chip--active {
  border: 2px solid #00ca99 !important;
  background-color: #fff !important;
  border-radius: 15px !important;
  color: #262626 !important;
}

.new-popup .v-card .v-chip--active:hover::before,
.new-popup .v-card .v-chip--active::before,
.new-popup .v-card .v-chip--active:active {
  opacity: 0 !important;
}

.new-popup .v-card .rated-object {
  color: #2196f3 !important;
  text-transform: inherit !important;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: normal !important;
  padding: 0 !important;
  opacity: 1 !important;
  border: 0 !important;
  text-decoration: none !important;
  outline: 0 !important;
}

.d-block {
  display: block !important;
}

.d-flex {
  display: flex !important;
}

.new-popup .v-card .v-card__subtitle,
.new-popup .v-card .v-card__text,
.new-popup .v-card .v-card__title {
  padding: 3px !important;
}

.new-popup .modal-footer .v-btn .v-icon,
.new-popup .modal-footer .v-btn img {
  margin-right: 6px !important;
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.modal-content.new-popup .mdi-menu-down {
  display: none !important;
}

.card-desc {
  overflow: hidden !important;
  width: 318px !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 3 !important;
  -webkit-box-orient: vertical !important;
  text-overflow: ellipsis !important;
  color: rgba(38, 38, 38, 0.87) !important;
  font-size: 16px !important;
  margin-top: 12px !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.new-popup .v-card .chat-views {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  flex-wrap: wrap !important;
}

.datarate-search-box input {
  padding: 14px 5px 5px !important;
}

.new-popup .v-card .chat-views .v-btn {
  color: #128d6f !important;
  background: transparent !important;
  box-shadow: unset !important;
  padding: 0 7px !important;
  opacity: 0.7 !important;
  font-size: 14px !important;
}

.new-popup .v-card .chat-views .v-btn .v-btn__content {
  justify-content: space-between;
}

.v-slide-group__next,
.v-slide-group__prev {
  min-width: auto !important;
  flex: 0 !important;
}

.info-icon-3 {
  position: absolute !important;
  z-index: 1000 !important;
  top: -15px;
}

@media (max-width: 575px) {
  .card-desc {
    width: 220px !important;
  }
}

@media (max-width: 430px) {
  .modal-content.new-popup {
    width: 100% !important;
  }

  .new-popup .v-card .rated-object {
    font-size: 13px;
  }

  .new-popup .modal-footer {
    padding-left: 6px !important;
    padding-right: 6px !important;
  }
}
</style>
