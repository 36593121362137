<template>
<div>
    <v-tooltip bottom v-if="chipData.length > 36">
        <template v-slot:activator="{ on, attrs }">
            <v-chip v-bind="attrs" v-on="on" class="chip-padding" @click="addSuggestedTag()">{{chipData.slice(0,36)}}<span>...</span></v-chip>
        </template>
        <span>{{chipData}}</span>
    </v-tooltip>
    <v-chip v-else class="chip-padding" @click="addSuggestedTag()">{{chipData}}</v-chip>

</div>
</template>

<script>
export default {
    props: {
        chipData: {
            type: String,
            default: ""
        },
        index: {
            type: Number,
            default: null
        }
    },
    name: "innerTags",
    data() {
        return {
            showFullText: false,
        }
    },
    methods: {
        addSuggestedTag() {
            if (this.index !== null) {
                this.$store.state.rating.rate.tags.push(this.$store.state.rating.suggestedTags[this.index])
                this.$store.state.rating.suggestedTags.splice(this.index, 1)
            }
        }
    }
}
</script>

<style scoped>
.chip-padding {
    padding: 5px 10px !important;
    font-weight: 500 !important;
}
</style>
