<template>
<v-card class="mx-auto my-12" max-width="374">
    <div class="card-header">
        <v-card-title class="title" v-if="item.title">
            {{ item.title }}</v-card-title>
        <a :href="item.page_url" target="_blank">{{ item.page_url }}</a>
    </div>

    <v-card-text class="rating-section">
        <v-row align="center" class="mx-0 rating-row" flex="wrap">
            <v-rating size="14" :value="item.average_stars" readonly></v-rating>

            <div class="grey--text ms-4">
                {{ item.average_stars }} <span> / 5</span>
            </div>
            <div class="grey--text">
                <span class="based-on">({{ $t("message['Based on']")}} {{ item.rate_count }} {{$t("reviews")}})</span>
            </div>
        </v-row>
    </v-card-text>

    <v-card-text>
        <v-chip-group flex="wrap">
            <v-chip v-for="(tag, index2) in item.tags" :key="index2">{{
                  tag
                }}</v-chip>
        </v-chip-group>

        <div class="card-desc cursor-pointer">
            {{ item.content }}
        </div>
    </v-card-text>

    <v-card-actions class="chat-views">
        <a text class="rated-object" @click="getPeopleMarks(item.id)">
            {{ item.total_rates }} {{ $t("message.marks") }}
        </a>
        <a text class="rated-object content-mark" @click="scrollToDiv(item.css_path,item.content)">
            {{ $t("message.content") }}
        </a>

        <div>
            <!-- <v-btn> <v-icon>mdi-message-text</v-icon> 12 </v-btn> -->
            <v-btn>
                <v-icon>mdi-eye</v-icon>
                <span class="ml-1"> {{ item.views_count }} </span>
            </v-btn>
        </div>
    </v-card-actions>
    <div class="text-right mb-2" v-if="showPeopleComments">
    <a text class="rated-object content-mark" @click="showPeopleComments = false">
            {{ $t("message.hideComments") }}
        </a>
    </div>
    <comments v-if="showPeopleComments" :comments-data="commentsData"></comments>
</v-card>
</template>

<script>
import comments from "../ratedObjectComponent/comments"
import HTTP from "../../services/api"
import {showErrorMessage,markBlue,unmarkBlue} from "../../../public/helper"
export default {
    name: "SearchDetail",
    props: ["item"],
    components: {
        comments
    },
    data() {
        return {
            showPeopleComments: false,
            commentsData:[],
            previousRating:{
                cssPath:null,
                content:null
            }
        }
    },
    methods:{
        getPeopleMarks(id){
            this.showPeopleComments = true
            HTTP.get(
          "/rates/" + id+ "/ratings"
        )
          .then((response) => {
              this.commentsData = response.data.ratings
          })
          .catch((error) => {
            // error = Error object,
            if (error.response.data.status == 401) {
              localStorage.removeItem("user_datarate");
            //   window.location.reload();
            }
            showErrorMessage(this.$store, error)
          });

        },
        scrollToDiv: function (css_path,content) {
            markBlue(css_path,content)
            if(this.previousRating.cssPath){
                unmarkBlue(this.previousRating.cssPath,this.previousRating.content)
            }
            this.previousRating.cssPath = css_path;
            this.previousRating.content = content;
            const xPathResult = document.evaluate(css_path, document);
            if (xPathResult) {
                const element = xPathResult.iterateNext();
                element.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
                this.$emit("previous-rating",this.previousRating)
                if(window.screen.width <= 430){
                this.$store.state.search.model = false;
            }
            }
        },
    }
}
</script>

<style>

</style>
