<template>
  <div>
    <transition name="fade">
      <div class="datarate-success-message grid-container rate-font-family" v-if="this.$store.state.successMessage" >
        <div style="padding-right: 10px"><img :src="media_url + 'stars.svg'" alt="data-rate-media" style="height: 20px;width: 20px;top: 5px"></div>
        <div style="color: #262626" class="dara-rate-property">{{ $t("message.ratingThanks") }}</div>
      </div>
    </transition>
  </div>

</template>

<script>
import {base_url_media} from "../../../public/helper"
export default {
  name: 'disable',
  data: function () {
    return {
      media_url : base_url_media
    }
  },
  methods: {
    close: function () {
    },


  }



}
</script>
<style>
.datarate-success-message{
  width: 18em !important;
  position: fixed !important;
  top: 0 !important;
  right: 1% !important;
  background: #FFFFFF !important;
  box-shadow: 0px 5px 26px 4px rgb(0 0 0 / 5%), 0px 7px 9px -4px rgb(0 0 0 / 10%) !important;
  padding: 2em !important;
  border-radius: 15px !important;
  z-index: 9999999999 !important;
}
.grid-container {
  display: grid !important;
  grid-template-columns: auto auto  !important;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s !important;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0 !important;
}
</style>

