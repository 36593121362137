<template>
  <div>
    <!--<img id="data-rate-start" class="data-rate-graded" src="https://d2kzr9hca36gxq.cloudfront.net/stars.png" v-for="(grad,index) in this.$store.state.gradedObject" :key="index"-->

    <!--   v-bind:style="{ top: grad.css_top +'px', left: grad.css_left + 'px' }" />-->
    <!--    <grading-items></grading-items>-->
  </div>

</template>

<script>

import $ from 'jquery'
import store from "../../../store";

export default {
  name: 'gradingStars',
  components: {
    // gradingItems
  },
  data: function () {
    return {}
  },
  mounted() {
    $(document.body).on("click", ".go-right", function () {
      let indexFound
    let imgs = $(this).parent().children("img")
    imgs.map((index,img)=>{
       if(img.classList.contains('d-block')){
       indexFound = index
     }}
     )
     imgs.map((index,img)=>{
       if(index === indexFound && index + 1 !== imgs.length){
          img.classList.add("d-none")
          img.classList.remove("d-block")
          setTimeout(()=>{
          imgs[index+1].classList.add("d-block")
          imgs[index+1].classList.remove("d-none")
          },500)
       }
     })
})

  $(document.body).on("click", ".go-left", function () {
      let indexFound
    let imgs = $(this).parent().children("img")
    imgs.map((index,img)=>{
       if(img.classList.contains('d-block')){
       indexFound = index
     }}
     )
     imgs.map((index,img)=>{
       if(index === indexFound && index !== 0){
          img.classList.add("d-none")
          img.classList.remove("d-block")
          setTimeout(()=>{
          imgs[index-1].classList.add("d-block")
          imgs[index-1].classList.remove("d-none")
          },500)
       }
     })
})
    document.body.addEventListener('click', event => {
      if (('data-rate-graded data-rate-start dara-rate-toggle').includes(event.target.className)) {
        $(event.target).removeClass('dara-rate-toggle');
        store.dispatch('getSpecificRatedObject',event.target.getAttribute('content-index'));
      }
    });


  }

}
</script>

<style>
.data-rate-graded{
  position: absolute;
  cursor: pointer;
  border: none !important;
}
.data-star-span{
  position: absolute !important;
  display: inline-flex !important;
    height: 35px;
    width: 35px;
    align-items:center;
}
.data-star-span .data-rate-graded{
  position: relative !important
}
.data-star-span::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
  height:10px;
}
.data-star-span button{
  width:15px;
  height: 15px;
  border-radius: 50%;
}
.data-star-span button.go-left{
  transform: rotate(180deg);
}
.data-star-span button img{
  width:10px;
  height: 10px;
}

.data-star-span::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
}
</style>
