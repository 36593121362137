<template>

  <div>
    <div class="comments_section ml-8" v-for="(comment,index) in ratingComments" :key="index">
      <div class="">
        <div class="user_profile">
          <div class="user_name d-flex justify-space-between">
            <div class="" style="bottom: 20px !important;">
              <img
                  :src="comment.profile_image_url"
                  class="rounded-circle" alt="data-rate-media" height="46" width="46">
              <div class="">
                <h4 class="ml-1 mb-0 pb-0 text-start">{{ comment.user_name }}</h4>
                <div class="user_stars d-flex align-center">
                  <!-- <v-rating
                      color="yellow darken-3"
                      :value="comment.stars"
                      background-color="blue-grey lighten-5"
                      empty-icon="$ratingFull"
                      large
                      readonly
                  ></v-rating> -->
                </div>
              </div>
            </div>
            <div class="user_date" :class="this.$vuetify.rtl ? 'flex-row-reverse':''">{{ timeSince(comment.create_time) }} <span class="ml-1"> {{$t("message.Ago")}}</span></div>
          </div>
        </div>
        <!-- <div class="mt-5">
          <v-chip class="mr-2 mb-2" v-for="(chip, index) in comment.tags" :key="index">
            {{ chip }}
          </v-chip>
        </div> -->
        <div class="user_comments">
          <p class="mb-0">
            {{ comment.content }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "rateComments",
  props: ["ratingComments","currentLang"],
  data() {
    return {}
  },
  methods: {
    getDuration: function (seconds) {
      var DURATION_IN_SECONDS = {
        epochs: ['year', 'month', 'day', 'hour', 'minute'],
        year: 31536000,
        month: 2592000,
        day: 86400,
        hour: 3600,
        minute: 60
      };
      var epoch, interval;

      for (var i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
        epoch = DURATION_IN_SECONDS.epochs[i];
        interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
        if (interval >= 1) {
          return {
            interval: interval,
            epoch: this.this.$vuetify.rtl  ? this.getHebrewDate(epoch, interval) : epoch
          };
        }
      }
    },
    getHebrewDate(time, interval){
      if (time === 'year') {
        if(interval > 1) return 'שנים'
        return 'שָׁנָה'
      }
      else if (time === 'month') {
        if(interval > 1) return 'חודשים'
        return 'חוֹדֶשׁ'
      } 
      else if (time === 'day') {
        if(interval > 1) return 'ימים'
        return 'יְוֹם'
      }
      else if (time === 'hour') {
        if(interval > 1) return 'שעות'
        return 'שָׁעָה'
      }
      else if (time === 'minute') {
        if(interval > 1) return 'דקות'
        return 'דַקָה'
      }
    },
    timeSince: function (date) {
      var seconds = Math.floor((new Date() - new Date(date)) / 1000);
      var duration = this.getDuration(seconds);
      var suffix = ((duration.interval > 1 || duration.interval === 0) && this.currentLang === 'en') ? 's' : '';
      return duration.interval + ' ' + duration.epoch + suffix;
    }
  }
}
</script>
