<template>
    <div class="data-rate-star-group-2 dara-rate-property" style="margin: auto;text-align: center">
      <img class="data-rate-star dara-rate-property" alt="data-rate-media" :class="star ? 'star-setting':''" v-for="(star, index) in evaluatedStars" :src="star ? media_url+'star-blue.svg' : media_url+'disabledStar.png'" :key="index"
           v-on:click="toggleStar(index)">
  
  
    </div>
  </template>
  
  <script>
  import {base_url_media} from "../../../public/helper"
  export default {
    name: 'ratingDialoge',
  
    data: function () {
      return {
        stars:2,
        media_url:base_url_media
  
      }
    },
    methods: {
      toggleStar: function (index) {
        this.$store.state.rating.rate.rate_points=index+1;
      }
    },
    computed:{
      evaluatedStars:function (){
        let stars=[];
        for (let i=1;i<6;i++){
          if(i<=this.$store.state.rating.rate.rate_points){
            stars.push(true)
          }else{
            stars.push(false)
          }
        }
        return stars;
      }
    }
  
  
  }
  </script>
  <style>
  .data-rate-star-group-2{
    border-radius: 25px !important;
    background: #F8F8F8 !important;
    width: 60% !important;
    padding: 2% !important;
  }
  .data-rate-star{
    cursor: pointer !important;
    padding: 2px !important;
    display: inline-block !important;
  }
  .star-setting{
    position: relative;
    top: 3px;
  }
  </style>
  
  