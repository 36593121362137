<template>
<div class="d-flex align-items-center">
        <img src="https://icon-library.com/images/info-icon-transparent/info-icon-transparent-5.jpg" width="20"
             height="20" class="cursor-pointer" alt="data-rate-media"
              @click="$store.state.info.model = true"/>
              <v-btn class="white--text"  :class="this.$vuetify.rtl ? 'ml-auto mr-2' : 'mr-auto ml-2'"
 v-if="!$store.state.user" x-small :href="
            'https://www.app.datarate.io/login?is_extension=true&redirect_url='+redirectDomain
          " color="primary">{{ $t("message.login") }}
      </v-btn>
      <div :class="this.$vuetify.rtl ? 'ml-auto' : 'mr-auto'">
        <ProfileComponent v-if="$store.state.user" @userData="getUserData" @logout="logout"/>
      </div>
              <v-select
  :items="$store.state.languages"
  variant="solo"
  @change="changeLanguage()"
  class="language-selector"
  :class="this.$vuetify.rtl ? 'ml-2' : 'mr-2'"
  v-model="$store.state.currentLang"
  item-text="name"
  item-value="lang"
></v-select>
<span
          class="dara-rate-property close-btn"
          style="margin-right: 5px !important"
          v-on:click="close()"
      >&times;</span
      >
      </div>

</template>
<script>
import ProfileComponent from "../ProfileComponent/ProfileComponent"
export default {
  name: "Header",
  components:{
    ProfileComponent
  },
  methods:{
    close(){
        this.$emit("close")
    },
    changeLanguage(){
      this.$store.dispatch('changeLanguage',{
        languageSetter:this.$vuetify
      })
    },
    getUserData(data){
        this.$emit("userData",data)
    },
    logout(){
        this.$emit("logout")
    }
  }
}
</script>