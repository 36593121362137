<template>
    <div>
        <div>
            <VueTags v-model="$store.state.rating.rate.tags">
                <div class="tag-input dara-rate-property" slot-scope="{tag,removeTag,inputEventHandlers,inputBindings }">
                    <span v-for="(tag,index) in $store.state.rating.rate.tags" :key="index">
                        <span class="tag-input__tag dara-rate-property" v-if="tag !=true">
                            <span class="dara-rate-property" style="color: #262626">{{ tag }}</span>
                            <span @click='removeTags(index)'><img :src="media_url + 'removeTag.png'" alt="" style="margin-left: 2px;position: relative;top: 3px;"></span>
                        </span>
                        <span class="tag-input__tag dara-rate-property" v-if="index==5 && tag==true " style="color: #939393;background: unset;cursor: pointer" @click="displayAllTags()">+5</span>
                    </span>
                    <input style="width: 350px; font-size: 14px !important;" class="tag-input__text dara-rate-property f-14" :placeholder='$store.state.rating.rate.tags.length > 0 ? "" : $t("message.tagsFeedback")' v-on="inputEventHandlers" v-bind="inputBindings" :disabled='$store.state.rating.rate.tags.length<100 ? false : true'>
                </div>
            </VueTags>
        </div>
      <div style="margin-top: 10px"  v-if="$store.state.rating.is_loading_tags">
      <img style="margin-top: 10px; height: 24px; width: 24px;" :src="media_url + 'loading.gif'">
      </div>
        <div style="margin-top: 1em">
            <v-chip-group column multiple>
                <inner-tags v-for="(suggestedTag,index)  in  suggestedTags" :key="index" class="mr-2 mb-2" :index="index" :chip-data="suggestedTag"></inner-tags>
            </v-chip-group>
            <a text class="rated-object mb-0 show-more-tags" @click="tagsCount +=5" v-if="$store.state.rating.suggestedTags.length > 5 && tagsCount < $store.state.rating.suggestedTags.length">+5</a>
        </div>

    </div>
    </template>

    <script>
    import VueTags from "vue-tags";
    import {
        base_url_media
    } from "../../../public/helper"
    import innerTags from "../ratedObjectComponent/innerTags"
    export default {
        components: {
            VueTags,
            innerTags
        },
        data() {
            return {
                tags: ['education'],
                tagsCount: 5,
                media_url: base_url_media
            }
        },
        computed: {
            suggestedTags() {
                return this.$store.state.rating.suggestedTags?.slice(0, this.tagsCount)
            }
        },
        methods: {
            addTag(event) {
                event.preventDefault()
                var val = event.target.value.trim()
                if (val.length > 0) {
                    this.$store.state.rating.rate.tags.push(val)
                    event.target.value = ''
                }
            },
            removeTags(index) {
                this.$store.state.rating.rate.tags.splice(index, 1)
            },
            addSuggestedTag(index) {
                this.$store.state.rating.rate.tags.push(this.$store.state.rating.suggestedTags[index])
                this.$store.state.rating.suggestedTags.splice(index, 1)
            },
            displayAllTags() {
                this.$store.state.rating.rate.dispay_only_five_tags = false;
            },
        },


    }
    </script>

    <style scoped>
    .tag-input {
        width: 100% !important;
        border: none !important;
        font-size: 0.9em !important;
        /*height: 50px  !important;*/
        box-sizing: border-box !important;
        padding: 0 10px !important;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;

    }

    .tag-input__tag {
        min-height: 30px !important;
        float: left !important;
        margin-right: 10px !important;
        background-color: #F3F3F3 !important;
        margin-top: 10px !important;
        line-height: 30px !important;
        padding: 4px 20px !important;
        border-radius: 25px !important;
    }

    .tag-input__tag>span {
        cursor: pointer !important;
        opacity: 0.75 !important;
    }

    .tag-input__text {
        border: none !important;
        outline: none !important;
        font-size: 0.9em !important;
        line-height: 50px !important;
        background: none !important;
        /*width: 25%  !important;*/
    }

    .data-rate-suggested-tags {
        border-radius: 25px !important;
        margin: 1% !important;
        padding: 2% !important;
        cursor: pointer !important;
        /*box-shadow: 0px 5px 26px 4px rgba(0, 0, 0, 0.05), 0px 7px 9px -4px rgba(0, 0, 0, 0.1)  !important;*/
        border: 1px solid #F3F3F3 !important;
        padding: 10px 20px !important;
        font-weight: normal !important;
        font-size: 14px !important;
        line-height: 24px !important;
        color: #262626 !important;
    }

    .justify-content-center {
        justify-content: center !important;
    }

    .show-more-tags {
        color: rgba(0, 0, 0, .5) !important;
    }
    </style>
