<template>
  <div class="datarate_modal rate-font-family dara-rate-property" ref="parentEl"
       :class="{'data-rate-hide':this.$store.state.rating.model}"
       style="z-index: 9999999;">

    <!-- Modal content -->
    <div class="modal-content dara-rate-property"
         style="padding-top: 0 !important;border-radius: 20px; margin-top: 10px !important; margin-right: 5px !important;">
      <movable style="position: relative;top: unset;left: unset;width: 100%" target="parentEl">
        <!--        <img class="data-rate-draggable" src="https://d2kzr9hca36gxq.cloudfront.net/dragable.jpeg" alt=""-->
        <!--             style="height: 20px;width: 20px;position: relative;left: 3em">-->
      </movable>
      <Header @logout="logout" @getUserData="getUserData" @close="close"/>
      <p class="dara-rate-property f-16"
         style="text-align: center;font-style: normal;font-weight: 500;font-size: 20px;line-height: 24px;color: #262626">
        {{ $t("message.rateContent") }}</p>
      <stars></stars>
      <div style="margin-top: 1em">
        <tags></tags>
      </div>
      <div style="margin-top: 2em">
        <comment></comment>
      </div>
      <div class="dara-rate-property" style="margin-top: 2em;text-align: center">
        <!--        todo- check the previous style term, cause it previous case ( check in commit history, the btton in case of page rate was gray-->
        <button class="dara-rate-property f-16"
                style=" border-radius: 25px;border: none;cursor: pointer; width: 200px; height: 40px"
                :style="false ? 'background-color:lightgray;color:black' : 'background-color:#00CA99;color:white'"
                v-on:click="rateObject">
          {{ $t("message.rateContent").toUpperCase() }}
        </button>
      </div>

    </div>

  </div>
</template>

<script>
import stars from './starsComponent'
import comment from './addCommentComponent'
import tags from "./tagsComponent";
import store from "../../../store";
import {
  unmarkBlue
} from "../../../public/helper";
import Header from '../Shared/Header.vue';
export default {
  name: 'ratingDialoge',
  components: {
    stars,
    comment,
    tags,
    Header
  },
  data() {
    return {
      redirectDomain: ''
    }
  },
  props: {
    currentLang: {
      type: String,
      default: "en",
    },
  },
  mounted() {
    this.redirectDomain = window.location.href;
  },
  methods: {
    getUserData(item) {
      this.userData = item;
    },
    logout() {
      localStorage.removeItem("user_datarate");
      this.$store.state.user = null;
      this.showLoginForm = false;
      this.userData = null;
    },
    close: function () {
      unmarkBlue(this.$store.state.rating.metadata.css_path, this.$store.state.rating.metadata.content);

      this.$store.state.rating.model = true;
    },
    rateObject: function () {
      if (localStorage.getItem("user_datarate")) {
        unmarkBlue(this.$store.state.rating.metadata.css_path, this.$store.state.rating.metadata.content);
        store.dispatch('rateObject', {})
      } else {
        // todo this is temporary do the same, i need to find a solution for not connecting users
        unmarkBlue(this.$store.state.rating.metadata.css_path, this.$store.state.rating.metadata.content);
        store.dispatch('rateObject')
        // store.dispatch('showAlert')

      }
    }

  }

}
</script>

<style>
/* The Modal (background) */


/* Modal Content */
.datarate_modal .modal-content {
  background-color: #fefefe !important;
  /*padding: 20px;*/
  padding: 20px 20px 20px 20px !important;

  border: 1px solid #888 !important;
  width: 25% !important;
  border-radius: 15px !important;
  box-shadow: 0px 5px 26px 4px rgba(0, 0, 0, 0.05), 0px 7px 9px -4px rgba(0, 0, 0, 0.1) !important;
  border: none !important;
}

/* The Close Button */
.datarate_close-3 {
  color: #aaaaaa !important;
  float: right !important;
  font-size: 28px !important;
  font-weight: bold !important;
  position: absolute !important;
  right: 15px !important;
  font-weight: bold !important;
  z-index: 1000 !important;
  top: 5px;
}

.datarate_close-he-3 {
  color: #aaaaaa !important;
  float: right !important;
  font-size: 28px !important;
  left: 0px !important;
  top: -25px !important;
  font-weight: bold !important;
  z-index: 1000 !important;
  position: absolute;
}

.info-icon-2 {
  top: 18px !important
}

.datarate_close-3:hover,
.datarate_close-3:focus,
.datarate_close-he-3:hover,
.datarate_close-he-3:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.datarate_modal .modal-content .data-rate-draggable {
  visibility: hidden !important;
}

.datarate_modal .modal-content:hover .data-rate-draggable {
  visibility: visible !important;
}
</style>
