<template>
<div class="datarate_modal rate-font-family dara-rate-property" ref="parentEl" style="z-index: 9999999;">

    <!-- Modal content -->
    <div class="modal-content-2 dara-rate-property" style="padding-top: 0 !important;border-radius: 20px">
        <movable style="position: relative;top: unset;left: unset;height: 20px;width: 100%" target="parentEl">
            <!--        <img class="data-rate-draggable" src="https://d2kzr9hca36gxq.cloudfront.net/dragable.jpeg" alt=""-->
            <!--             style="height: 20px;width: 20px;position: relative;left: 3em">-->
        </movable>
        <span class="datarate_close dara-rate-property" v-on:click="close">&times;</span>
        <p class="dara-rate-property" style="text-align: center;font-style: normal;font-weight: 500;font-size: 20px;line-height: 24px;color: #262626"> {{ $t("message.addKeywords")}} </p>
        <div style="margin-top: 1em">
            <tags></tags>
        </div>

        <div style="margin-top: 1em;text-align: center">
            <button class="dara-rate-property" style="    background: #00CA99; border-radius: 30px;padding: 1.1em 4em;
    border: none;cursor: pointer;color: white" v-on:click="sendKeywords" :disabled="$store.state.rating.rate.tags.length === 0">{{ $t("message.addKeywords").toUpperCase()}}
            </button>
        </div>
    </div>

</div>
</template>

<script>
import tags from "../StarComponent/tagsComponent";
import store from "../../../store";
import HTTP from '../../services/api';
import {
    showErrorMessage
} from "../../../public/helper";
export default {
    name: 'addKeywords',
    components: {
        tags
    },
    data: function () {
        return {}
    },
    methods: {
        close: function () {
            // unmarkBlue(this.$store.state.rating.metadata.css_path, this.$store.state.rating.metadata.content);
            this.$store.state.addKeywords.model = false;
            this.$store.state.rating.rate.tags = []
        },
        rateObject: function () {
            store.dispatch('rateObject');
        },
        sendKeywords() {
            HTTP.post("/tasks", {
                tags: this.$store.state.rating.rate.tags
            }).then((res) => {
                console.log(res)
                this.$store.state.rating.rate.tags = []
            }).catch((error) => {
                if (error.response.data.status == 401) {
                    localStorage.removeItem("user_datarate");
                    // window.location.reload();
                }
                else{
                    showErrorMessage(this.$store, error)
                }
            })
        }

    },
    mounted() {
        store.dispatch('ratingModel', {
            marked_text: "ADD KEYWORDS",
            csspath: "/div/div/div[8]/div/div[3]/button",
            object_id: '',
            ratingModel: true
        });
    }
}
</script>

<style>
/* The Modal (background) */
.datarate_modal {

    position: fixed !important;
    /* Stay in place */
    z-index: 1 !important;
    /* Sit on top */
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    /* Full width */
    height: 100% !important;
    /* Full height */
    overflow: auto !important;
    /* Enable scroll if needed */
    /*background-color: rgb(0, 0, 0); !* Fallback color *!*/
    /*background-color: rgba(0, 0, 0, 0.4); !* Black w/ opacity *!*/
}

/* Modal Content */
.datarate_modal .modal-content-2 {
    background-color: #fefefe !important;
    margin: auto !important;
    /*padding: 20px;*/
    padding: 20px 20px 20px 20px !important;

    border: 1px solid #888 !important;
    border-radius: 15px !important;
    box-shadow: 0px 5px 26px 4px rgba(0, 0, 0, 0.05), 0px 7px 9px -4px rgba(0, 0, 0, 0.1) !important;
    border: none !important;
}

/* The Close Button */
.datarate_close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.datarate_close:hover,
.datarate_close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.datarate_modal .modal-content .data-rate-draggable {
    visibility: hidden;
}

.datarate_modal .modal-content:hover .data-rate-draggable {
    visibility: visible;
}
</style>
