<template>
  <div
      class="datarate_modal rate-font-family dara-rate-property"
      ref="parentEl"
      style="z-index: 9999999"
  >
    <!-- Modal content -->
    <div
        class="modal-content dara-rate-property"
        :class="this.$vuetify.rtl ? 'position-relative' : ''"
        style="
        padding-top: 0 !important;
        margin-top: 10px !important;
        margin-right: 5px !important;
      "
    >
      <movable
          style="
          position: relative;
          top: unset;
          left: unset;
          width: 100%;
        "
          target="parentEl"
      >
        <!--        <img class="data-rate-draggable" src="https://d2kzr9hca36gxq.cloudfront.net/dragable.jpeg" alt=""-->
        <!--             style="height: 20px;width: 20px;position: relative;left: 3em">-->
      </movable>
      <Header @logout="logout" @getUserData="getUserData" @close="close"/>

      <div>
        <div class="modal-heading d-block">
          <!--        <div class="position-relative">-->
          <!--          <img src="https://icon-library.com/images/info-icon-transparent/info-icon-transparent-5.jpg" width="18"-->
          <!--               height="18" class="cursor-pointer" alt="data-rate-media"-->
          <!--               :class="this.$vuetify.rtl ? 'info-icon' : 'info-icon-3'" @click="$store.state.info.model = true"/>-->

          <!--        </div>-->
          <div class="d-flex heading-section justify-space-between">
            <!--          <div class="datarate-heading mt-5">-->
            <!--            <img :src="media_url + 'stars.svg'" alt="data-rate-media"/>-->
            <!--            <h4>{{ $t("message.datarate") }}</h4>-->
            <!--          </div>-->

            <!--            <span :class="this.$vuetify.rtl ? 'close-he-4' : 'close-4'" v-on:click="close">&times;</span>-->
          </div>
        </div>

      </div>
      <v-expansion-panels multiple v-model="panel">
        <v-expansion-panel>
          <p class="dara-rate-property mb-0" style="margin-top: 1em">
            <span class="dara-rate-property">
              <v-expansion-panel-header
                  :expand-icon="panel.indexOf(0) > -1 ? '' : ''"
                  style="font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #262626;
                "
              >
                {{ $t("message.ratingHeader") }}
              </v-expansion-panel-header>
            </span>
            <v-expansion-panel-content>
              <v-dialog
                  v-model="dialog"
                  width="500"
                  id="dialog-delete"
                  v-if="
                  this.$store.state.ratedObject.rating_response
                    .delete_this_object_allowed
                "

              >
                <template v-slot:activator="{ on, attrs }">
                  <img
                      v-bind="attrs"
                      v-on="on"
                      :class="
                      this.$vuetify.rtl ? 'float-left' : 'float-right'
                    "
                      class="dara-rate-property img-delete"
                      :src="media_url + 'delete.png'"
                      alt=""
                      style="height: 20px"
                  />
                </template>

                <v-card>
                  <v-card-title class="text-h5 text-center d-block">
                    {{ $t("message.deleteRatingBtn") }}
                  </v-card-title>

                  <v-card-text class="text-center">
                    {{ $t("message.deleteRating") }}
                  </v-card-text>

          <div class="text-center justify-space-between">
            <v-spacer></v-spacer>

            <v-btn
                color="error"
                class="mb-3"
                :class="
                      this.$vuetify.rtl ? 'ml-3' : 'mr-3'
                    "
                @click="deleteRating"
            >
              Yes
            </v-btn>
            <v-btn color="primary" class="mb-3" @click="dialog = false">
              No
            </v-btn>
          </div>
          </v-card>
          </v-dialog>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <img
                  v-bind="attrs"
                  @click="addToBookMark"
                  v-on="on"
                  class="dara-rate-property"
                  :src="media_url + 'bookmark.png'"
                  alt="data-rate-media"
                  style="height: 20px; cursor: pointer"
                  :class="
                      this.$vuetify.rtl ? 'float-left ml-3' : 'float-right mr-3'
                    "
              />
            </template>
            <span>{{ $t("message.bookmark") }}</span>
          </v-tooltip>
          <!--     todo- enable it when functionality exist-->
          <!--            <img class="dara-rate-property" :src="media_url + 'share.png'" alt="" style="height: 20px;float: right;margin-right: 20px">-->
          <div>
            <stars
            @rateFullPage="open_model()"
                :currentLang="currentLang"
                :averageStars="
                    this.$store.state.rate_type === 'full'
                      ? this.$store.state.ratedObject.pageRate
                      : this.$store.state.ratedObject.rating_response
                          .average_stars ? this.$store.state.ratedObject.rating_response
                          .average_stars : 0
                  "
            ></stars>
          </div>
          </v-expansion-panel-content>
          </p>
        </v-expansion-panel>
        <v-expansion-panel>
          <div style="margin-top: 1em">
            <p class="mb-0">
              <span
                  class="dara-rate-property"
                  style="
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #262626;
                "
              >
                <v-expansion-panel-header
                    :expand-icon="panel.indexOf(1) > -1 ? '' : ''"
                    style="
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #262626;
                  "
                >
                  {{ $t("message.popularTags") }}
                </v-expansion-panel-header>
              </span>
            </p>
            <v-expansion-panel-content>

              <div class="mt-n5">
                <tags-general-page
              v-if="this.$store.state.rate_type === 'full'"
                ></tags-general-page>
                <tags v-else></tags>
              </div>
            </v-expansion-panel-content>
          </div>
        </v-expansion-panel>
      </v-expansion-panels>

      <!--        <stars v-if="$store.state.ratedObject.pageRateMode"></stars>-->

      <div v-if="$store.state.productEnabledFeatures.showRecommendations">
        <p>
          <span
              class="dara-rate-property"
              style="
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #262626;
            "
          >{{ $t("message.recommendation") }}</span
          >
        </p>
        <p>
          <a
              href="https://news.walla.co.il/item/2743096"
              class="dara-rate-property"
              style="
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #262626;
            "
          >אתם בחרתם: מיהו המנהיג הגדול ביותר שהיה למדינה?</a
          >
        </p>
        <p>
          <a
              href="https://news.walla.co.il/item/3200511"
              class="dara-rate-property"
              style="
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 24px;
              color: #262626;
            "
          >בנימין נתניהו - ההחלטות שהצילו את המדינה</a
          >
        </p>
      </div>

      <div style="margin-top: 1em" class="mb-7">
        <a
            text
            class="dara-rate-property"
            href="#"
            v-if="!this.$store.state.ratedObject.is_comment_hidden && this.$store.state.ratedObject.pageRateResponse && this.$store.state.ratedObject.pageRateResponse.rate_count"
            style="
            color: #2196f3 !important;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
          "
            v-on:click="show_comment_box($event)"
        >{{ showDefaultComments ? $t("message.showMore") : $t("message.comments") }}</a
        >

        <a
            text
            class="dara-rate-property"
            href="#"
            v-if="this.$store.state.ratedObject.is_comment_hidden"
            style="
            color: #2196f3 !important;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
          "
            v-on:click="hide_comment_box($event)"
        >{{ $t("message.hideComments") }}</a
        >
        <!--          todo- make it active when view functionality is working-->

        <span
            class="dara-rate-property"
            :class="this.$vuetify.rtl ? 'float-left ml-3' : 'float-right'"
            style="position: relative; margin-right: 5%"
        >
          <!--          <img-->
          <!--              style="height: 18px;opacity: 0.7;display: inline-block" :src="media_url+ 'eye.png'" alt="">-->
          <span class="comments_length">

            {{
              this.getCommentsCount()
            }} {{ $t("message.commentsText") }}
          </span></span
        >
        <span
            class="dara-rate-property"
            style="margin-right: 5%; position: relative"
        >
          <!-- <img
          class="mt-1"
            style="height: 18px; display: inline-block"
            :src="media_url + 'chat.png'"
            alt=""
            :class="this.$vuetify.rtl ? 'float-left ml-8' : 'float-right mr-5'"
          /> -->
          <!-- <span
            style="padding-left: 10px; color: #138d6f; position: absolute;"
            :class="this.$vuetify.rtl ? 'float-left' : 'float-right'"
            >{{
              this.$store.state.ratedObject.rating_response.views_count
            }}</span
          > -->
          </span
          >
      </div>
      <!-- <commentBox></commentBox> -->
      <div v-if="$store.state.ratedObject.is_comment_hidden">
        <comments
            v-for="(comment, index) in this.$store.state.comments_list"
            :key="index"
            :comment-data="comment"
        ></comments>
      </div>
      <div v-else-if="!$store.state.ratedObject.is_comment_hidden && $store.state.comments_list.length > 0">
        <comments
            v-for="(comment, index) in this.$store.state.comments_list.slice(0,showDefaultComments)"
            :key="index"
            :comment-data="comment"
        ></comments>
      </div>
      <div
          class="dara-rate-property"
          style="margin-top: 2em; text-align: center"
      >
        <!--        todo- check the previous style term, cause it previous case ( check in commit history, the btton in case of page rate was gray-->
        <button
            class="dara-rate-property f-16"
            style="
            border-radius: 25px;
            border: none;
            cursor: pointer;
            width: 200px;
            height: 40px;
          "
            :style="
            false
              ? 'background-color:lightgray;color:black'
              : 'background-color:#00CA99;color:white'
          "
            v-on:click="open_model()"
        >
          {{ $t("message.button") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import stars from "./starsComponent";
import tags from "./tagsComponentObjectRate";
import tagsGeneralPage from "./tagsComponenGeneralRate";
import comments from "./comments.vue";
import store from "../../../store";
import $ from "jquery";
import {showDefaultComments} from "../../../public/helper";

import {
  unmarkBlue,
  showErrorMessage,
  base_url_media,
} from "../../../public/helper";
import getElementByXpath from "../../../public/helper";
import HTTP from "../../services/api";
import Header from "../Shared/Header.vue";
export default {
  name: "ratingDialoge",
  components: {
    stars,
    tagsGeneralPage,
    tags,
    comments,
    Header
  },
  data: function () {
    return {
      redirectDomain: '',
      showLoginForm: false,
      dialog: false,
      userData: null,
      media_url: base_url_media,
      panel: [0, 1],
      showDefaultComments,
      id:""
    };
  },
  props: {
    currentLang: {
      type: String,
      default: "en",
    },
  },
  watch: {
    "$store.state.ratedObject.model": function (val) {
      if (val) {
        this.panel = [0, 1];
      }
    },
  },
  mounted() {
    this.$store.state.ratedObject.is_comment_hidden = false;
    this.redirectDomain = window.location.href;
    this.$store.state.selectedObject_id =  this.$store.state.rate_type === 'full' ? this.$store.state.ratedObject.pageRateResponse.id: this.$store.state.ratedObject.rating_response.id;
    this.id = this.$store.state.selectedObject_id
    store.dispatch("getCommentsOfSelectedObject");
  },
  methods: {
    getCommentsCount() {
      if(this.$store.state.rate_type === 'full'){
        return this.$store.state.ratedObject.pageRateResponse?.rate_count ? this.$store.state.ratedObject.pageRateResponse?.rate_count : 0
      }
      return this.$store.state.ratedObject.rating_response?.rate_count ? this.$store.state.ratedObject.rating_response?.rate_count : 0
    },
    logout() {
      localStorage.removeItem("user_datarate");
      this.$store.state.user = null;
      this.showLoginForm = false;
      this.userData = null;
    },
    getUserData(item) {
      this.userData = item;
    },
    setFormValue(item) {
      this.showLoginForm = item;
    },
    deleteRating: function () {
      HTTP.delete("/rates/" + this.$store.state.ratedObject.rating_response.id)
          .then(() => {
            this.dialog = false;
            this.$store.state.remarks.imgSrc = base_url_media + "avatar1.svg";
            this.$store.state.remarks.model = true;
            this.$store.state.ratedObject.model = true;
            this.removeBackground();
            $("#data-rate-start" + this.$store.state.ratedObject.index).remove();
            this.$store.state.remarks.htmlPattern =
                '<h3 class="heading">Rating Deleted Successfully</h3>';
            setTimeout(() => {
              this.$store.state.remarks.htmlPattern = "";
              this.$store.state.remarks.model = false;
            }, 3000);
          })
          .catch((error) => {
            if (error.response.data.status === 401) {
              localStorage.removeItem("user_datarate");
              // window.location.reload();
            } else {
              showErrorMessage(this.$store, error);
            }
          });
    },
    addToBookMark: function () {
      HTTP.post("/bookmarks", {
        rated_object_id: this.$store.state.ratedObject.rating_response.id,
      })
          .then(() => {
            this.$store.state.remarks.imgSrc = base_url_media + "avatar1.svg";
            this.$store.state.remarks.model = true;
            this.$store.state.remarks.htmlPattern =
                '<h3 class="heading">Added to Your Bookmarks Successfully</h3>';
            setTimeout(() => {
              this.$store.state.remarks.htmlPattern = "";
              this.$store.state.remarks.model = false;
            }, 3000);
          })
          .catch((error) => {
            if (error.response.data.status === 401) {
              localStorage.removeItem("user_datarate");
              // window.location.reload();
            } else {
              showErrorMessage(this.$store, error);
            }
          });
    },
    close: function (e) {
      this.$store.state.ratedObject.model = true;
      var gradedObject =
          this.$store.state.gradedObject[this.$store.state.ratedObject.index];
      unmarkBlue(gradedObject.css_path, gradedObject.content);
      this.$store.state.ratedObject.pageRateMode = false;
      this.hide_comment_box(e);

      this.removeBackground();
    },
    removeBackground() {
      $("#data-rate-start" + this.$store.state.ratedObject.index).addClass(
          "dara-rate-toggle"
      );
      var index = this.$store.state.ratedObject.index;
      var string = $(
          getElementByXpath(store.state.gradedObject[index].css_path)
      ).html();
      var fromtxt =
          '<span style="background: lightgreen">' +
          store.state.gradedObject[index].content +
          "</span>";
      var newText = string.replace(
          fromtxt,
          store.state.gradedObject[index].content
      );
      $(getElementByXpath(store.state.gradedObject[index].css_path)).empty();
      $(getElementByXpath(store.state.gradedObject[index].css_path)).append(
          newText
      );
      this.$store.state.ratedObject.index = 0;
    },
    show_comment_box: function (e) {
      e.preventDefault();
      this.panel = [];
      this.$store.state.ratedObject.is_comment_hidden = true;
    },
    hide_comment_box: function (e) {
      e.preventDefault();
      this.$store.state.ratedObject.is_comment_hidden = false;
      this.panel = [0, 1];
    },
    open_model: function () {
      this.$store.state.ratedObject.model = true;
      if (this.$store.state.rating.metadata['is_image'] || this.$store.state.rating.metadata['is_video']) {
        store.dispatch("ratingModel", {
          marked_text: 'this is image test for backend api to test our develop of rate image',
          csspath: this.$store.state.rating.metadata.css_path,
          object_id: this.$store.state.rating.object_id,
          ratingModel: false,
          whole_page: false,
          media_url: this.$store.state.rating.metadata['media_url'],
          media_id: this.id
        });
      } else {
        var item = this.$store.state.gradedObject.find(
          (item) => item.id === this.id
      );
        store.dispatch("ratingModel", {
          marked_text: item
              ? item.content
              : "information about: " + window.document.title,
          csspath: item ? item.css_path : "",
          object_id: item ? item.id : "",
          ratingModel: false,
          whole_page: this.$store.state.rate_type === 'full' ? true : false,
        });
      }
    },
  },
};
</script>

<style>
/* The Modal (background) */
.datarate_modal {
  position: fixed !important;
  /* Stay in place */
  z-index: 1 !important;
  /* Sit on top */
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
  /* Full width */
  height: 100% !important;
  /* Full height */
  overflow: auto !important;
  /* Enable scroll if needed */
  /*background-color: rgb(0, 0, 0); !* Fallback color *!*/
  /*background-color: rgba(0, 0, 0, 0.4); !* Black w/ opacity *!*/
}

.heading-section {
  justify-content: space-between !important;
  align-items: center !important;
}

.modal-heading .heading-section {
  justify-content: space-between !important;
}

/* Modal Content */
.datarate_modal .modal-content {
  background-color: #fefefe !important;
  padding: 20px !important;
  border: 1px solid #888 !important;
  width: 20%;
  border-radius: 15px !important;
  box-shadow: 3px 6px 18px grey !important;
  border: none !important;
}

/* The Close Button */
.datarate_close {
  color: #aaaaaa !important;
  float: right !important;
  font-size: 28px !important;
  font-weight: bold !important;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.info-icon {
  position: absolute !important;
  z-index: 1000 !important;
  top: -22px;
}

.datarate_close:hover,
.datarate_close:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: pointer !important;
}
</style>

<style scoped>
.v-chip {
  white-space: normal !important;
}

.v-chip.v-size--default {
  height: auto !important;
}

.img-delete {
  filter: invert(81%) sepia(67%) saturate(0%) hue-rotate(233deg) brightness(88%) contrast(108%) !important;
}

.v-expansion-panel::before {
  box-shadow: none !important;
}

.position-relative {
  position: relative !important;
}

.v-expansion-panel-header,
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 !important;
}

.theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none !important;
}

.v-expansion-panel-header {
  min-height: auto !important;
}

.v-expansion-panel--active + .v-expansion-panel {
  margin-top: 0px !important;
}

#dialog-delete {
  z-index: 999999999 !important
}

.datarate-heading {
  display: flex;
  align-items: center;
}

.datarate-heading h4 {
  margin: 0 !important;
  padding: 0 !important;
}

.datarate-heading img {
  margin: 0 !important;
  padding: 0 !important;
}

.new-popup .modal-heading {
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #262626 !important;
  /* display: flex !important; */
  align-items: center !important;
  justify-content: space-between !important;
  border-bottom: none !important;
  padding-left: 28px !important;
  padding-right: 28px !important;
  padding-bottom: 24px !important;
}

.new-popup .modal-heading h4 {
  padding-left: 10px !important;
  padding-bottom: 0px !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.new-popup .modal-heading .main-section .my-profile {
  display: none;
}

.new-popup .modal-heading .main-section .user-header {
  margin-bottom: 0 !important;
}

.new-popup .modal-heading .main-section .img-section img.border-50 {
  width: 32px !important;
  height: 32px !important;
  margin-right: 10px !important;
}

.new-popup .modal-heading .main-section .img-section h4 {
  font-size: 14px !important;
  padding-left: 0 !important;
}


</style>
