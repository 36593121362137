import Vue from "vue";
import VueI18n from "vue-i18n";
import messages from "../language.json"
Vue.use(VueI18n);
const i18n = new VueI18n({
    messages, // set locale messages
  });

  export default i18n;

