<template>
  <span>
  <div class="data-rated-star-group" style="display: inline" :class="this.$vuetify.rtl ? 'p-0':''">
    <img class="data-rate-star" v-for="(star, index) in evaluatedStars" alt="data-rate-media"
         :src="star ? media_url + 'activeStar.png' : media_url + 'disabledStar.png'" :key="index" :data-value="index + 1"
         @click="getStarsValue($event)"
         style="width: 18px">


  </div>
  <div style="display: inline" class="f-16">
    <span class="dara-rate-property"><b class="dara-rate-property">{{

        this.$props.averageStars
      }}</b>/5</span>

  </div>
    </span>
</template>

<script>
import {base_url_media} from "../../../public/helper"

export default {
  name: 'ratingDialoge',
  props:['averageStars','currentLang'],
  data: function () {
    return {
      media_url: base_url_media

    }
  },
  methods: {
    getStarsValue(e){
      this.$store.state.rating.rate.rate_points = parseInt(e.target.getAttribute("data-value"))
      this.$emit('rateFullPage')
    }
  },
  computed: {
    evaluatedStars: function () {
      let stars = [];
      let objectStars = this.$props.averageStars
      for (let i = 1; i < 6; i++) {
        if (i <= objectStars) {
          stars.push(true)
        } else {
          stars.push(false)
        }
      }
      return stars;
    }
  }


}
</script>
<style>
.data-rated-star-group {
  border-radius: 25px !important;
  padding: 0 4% 0 0 !important;
}

.data-rate-star {
  cursor: pointer !important;
  display: inline-block !important;
}
.p-0{
  padding: 0 !important
}
</style>

