<template>
  <div class="datarate_modal rate-font-family dara-rate-property" ref="parentEl"
       @click="closeOnOutsideClick"
       style="z-index: 9999999;">

    <!-- Modal content -->
    <div class="modal-content dara-rate-property"
         style="padding-top: 0 !important;border-radius: 20px; margin-top: 10px !important; margin-right: 5px !important;">

      <div class="d-flex align-items-center justify-end" :class="this.$vuetify.rtl ? 'position-relative' : ''">
        <v-select
  :items="$store.state.languages"
  variant="solo"
  @change="changeLanguage()"
  class="language-selector"
  :class="this.$vuetify.rtl ? 'ml-2' : 'mr-2'"
  v-model="$store.state.currentLang"
  item-text="name"
  item-value="lang"
></v-select>
          <span class="dara-rate-property close-btn"
                v-on:click="close">&times;</span>
      </div>
      <v-btn class="white--text" v-if="$store.state.productEnabledFeatures.login&&!$store.state.user" x-small :href="
            'https://www.app.datarate.io/login?is_extension=true&redirect_url='+redirectDomain
          " color="secondary">{{ $t("message.login") }}
      </v-btn>
      <ProfileComponent v-if="$store.state.productEnabledFeatures.login&&$store.state.user" @userData="getUserData"
                        @logout="logout"/>
      <p class="dara-rate-property f-16 mx-auto"
         style="text-align: center;font-style: normal;font-weight: 500;font-size: 20px;line-height: 24px;color: #262626">
        {{ $t("message.feedbackDialogTitle") }}</p>

      <stars></stars>

      <div style="display: flex">
        <v-text-field
            style="width: 50%;"
            v-if="!userLogined"

            :label="$t('message.yourName')"
            :disabled="this.userLoginnedExternal"

            :placeholder="$t('message.yourName')"
            v-model="userName"
            @click:append="addFeedback()"
            color="secondary"
        ></v-text-field>
        <v-text-field
            style="width: 50%; margin-left: 5px;"
            :disabled="this.userLoginnedExternal"
            v-if="!userLogined"
            :label="$t('message.yourOrganization')"
            :placeholder="$t('message.yourOrganization')"
            v-model="userOrganization"
            @click:append="addFeedback()"
            color="secondary"
        ></v-text-field>
      </div>
      <div>
        <v-text-field
            v-if="!userLogined"


            :label="$t('message.email')"
            :placeholder="$t('message.email')"
            v-model="email"
            :disabled="this.userLoginnedExternal"
            @click:append="addFeedback()"
            color="secondary"
        ></v-text-field>
      </div>
      <div style="margin-top: 1em">
        <v-text-field
            :label="$t('message.addCommentText')"
            :placeholder="$t('message.addCommentText')"
            v-model="userComments"
            @click:append="addFeedback()"
            color="secondary"
        ></v-text-field>
      </div>
      <div class="d-flex cursor-pointer" @click="showAddTagsSections = !showAddTagsSections"
           style="margin-bottom:20px;">
        <v-icon color="secondary">mdi-plus-circle</v-icon>
        <h4 style="color: #6692FF; font-size: 16px;margin-left: 10px;">{{ $t("message.addTags") }}</h4>
      </div>
      <div style="margin-top: 1em" v-if="showAddTagsSections">
        <tags></tags>
      </div>
      <!--      $store.state.productEnabledFeatures.rightSearchButton-->

      <div class="d-flex cursor-pointer" @click="showTags()">
        <!--        <v-icon color="secondary">mdi-eye</v-icon>-->
        <h4 v-if="$store.state.user && $store.state.user.is_website_admin"
            style="color: #6692FF; font-size: 16px;margin-left: 10px;">Track page feedbacks</h4>
        <h4 v-if="($store.state.user && !$store.state.user.is_website_admin||!$store.state.user)
        &&$store.state.productEnabledFeatures.login &&$store.state.productEnabledFeatures.user_track_feedback"
            style="color: #6692FF; font-size: 16px;margin-left: 10px;">{{ $t("message.trackYourFeedback") }}</h4>
      </div>
      <div v-if="showCommentsSections" class="mt-3">
        <div class="d-flex align-items-center justify-space-between">
          <a
              text
              class="dara-rate-property"
              href="#"
              v-if="!this.$store.state.ratedObject.is_comment_hidden && this.$store.state.ratedObject.pageRateResponse && this.$store.state.ratedObject.pageRateResponse.rate_count"
              style="color: rgb(102, 146, 255) !important;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
          "
              v-on:click="show_comment_box($event)"
          >{{ $t("message.showMore") }}</a
          >

          <a
              text
              class="dara-rate-property"
              href="#"
              v-if="this.$store.state.ratedObject.is_comment_hidden"
              style="
            color: #2196f3 !important;
            text-decoration: none;
            font-size: 14px;
            font-weight: 500;
            font-style: normal;
          "
              v-on:click="hide_comment_box($event)"
          >{{ $t("message.hideComments") }}</a
          >
          <span class="comments_length">
{{ this.$store.state.feedback_comments_list.length }} {{ $t("message.commentsText") }}
</span>

        </div>
        <div v-if="$store.state.ratedObject.is_comment_hidden">
          <comments
              v-for="(comment, index) in this.$store.state.feedback_comments_list"
              :key="index"
              :comment-data="comment"
          ></comments>
        </div>
        <div v-else-if="!$store.state.ratedObject.is_comment_hidden && $store.state.feedback_comments_list.length > 0">
          <comments
              v-for="(comment, index) in this.$store.state.feedback_comments_list.slice(0,showDefaultComments)"
              :key="index"
              :comment-data="comment"
          ></comments>
        </div>
      </div>
      <div style="color:#747dfb;font-weight: bold;" class="people-marks" v-on:click="addFeedback">
        {{ $t("message.send") }}
        <!-- <comment></comment> -->
      </div>
      <!--        todo- check the previous style term, cause it previous case ( check in commit history, the btton in case of page rate was gray-->
      <!-- <button class="dara-rate-property f-16"
              style=" border-radius: 25px;border: none;cursor: pointer; width: 200px; height: 40px"
              :style="false ? 'background-color:lightgray;color:black' : 'background-color:#00CA99;color:white'"
              v-on:click="rateObject">
        {{ $t("message.rateContent").toUpperCase() }}
      </button> -->

    </div>

  </div>
</template>

<script>
import stars from './starsComponent'
//   import comment from './addCommentComponent'
import tags from "./tagsComponent";
import store from "../../../store";
import ProfileComponent from "../ProfileComponent/ProfileComponent";
import comments from "../ratedObjectComponent/comments.vue"
import {showErrorMessage, showDefaultComments} from "../../../public/helper"
export default {
  name: 'FeedbackDialog',

  data() {
    return {
      userLoginnedExternal:this.$store.state.userLoginnedExternal,
      externalLoginIsRequired:this.$store.state.externalLoginIsRequired,
      redirectDomain: '',
      showAddTagsSections: false,
      userComments: "",
      userName: "",
      email: "",
      userOrganization: "",
      userData: null,
      userLogined: localStorage.getItem('user_datarate'),
      showCommentsSections: false,
      showDefaultComments
    }
  },
  components: {
    stars,
    tags,
    ProfileComponent,
    comments
  },
  props: {
    currentLang: {
      type: String,
      default: "en",
    },
  },
  methods: {
    changeLanguage(){
      this.$store.dispatch('changeLanguage',{
        languageSetter:this.$vuetify
      })
    },
    showTags: function () {
      if (this.$store.state.user) {
        this.showCommentsSections = !this.showCommentsSections
        if (this.showCommentsSections) {
          store.dispatch('getCommentsOfFeedback')
        }
      } else {
        showErrorMessage(this.$store, {
          response: {
            data: {
              error: this.$vuetify.rtl ? ` כדי לעקוב אחר תהליך המשוב שלך, עליך להתחבר ולתת משוב כמשתמש לא אנונימי ` : `For track your feedback process, you must to <a href='https://www.app.datarate.io/login?is_extension=true&redirect_url=${this.redirectDomain}'>login</a>`
            }
          }
        })
      }
    },
    close: function () {
      this.$store.state.feedback.model = false
    },
    closeOnOutsideClick(event) {
      if (event.target === this.$refs.parentEl) {
        this.close();
      }
    },
    addFeedback: function () {
      store.dispatch('addFeedback', {
        comment: this.userComments,
        name: this.userName,
        organization: this.userOrganization,
        email: this.email
      })
    },
    getUserData(item) {
      this.userData = item;
    },
    show_comment_box: function (e) {
      e.preventDefault();
      this.$store.state.ratedObject.is_comment_hidden = true;
    },
    hide_comment_box: function (e) {
      e.preventDefault();
      this.$store.state.ratedObject.is_comment_hidden = false;
    },
    logout() {
      localStorage.removeItem("user_datarate");
      this.$store.state.user = null;
      this.showLoginForm = false;
      this.userData = null;
    },
  },
  mounted() {
    store.dispatch('getFeedbackTags')
    this.$store.state.ratedObject.is_comment_hidden = false;
    this.redirectDomain = window.location.href;
    this.$store.state.selectedObject_id = this.$store.state.ratedObject.pageRateResponse.id;
    this.id = this.$store.state.selectedObject_id
    store.dispatch("getCommentsOfSelectedObject");
  }
}
</script>

<style>
/* The Modal (background) */

/* Modal Content */
.datarate_modal .modal-content {
  background-color: #fefefe !important;
  /*padding: 20px;*/
  padding: 20px 20px 20px 20px !important;

  border: 1px solid #888 !important;
  width: 25% !important;
  border-radius: 15px !important;
  box-shadow: 0px 5px 26px 4px rgba(0, 0, 0, 0.05), 0px 7px 9px -4px rgba(0, 0, 0, 0.1) !important;
  border: none !important;
}

/* The Close Button */
.datarate_close-3 {
  color: #aaaaaa !important;
  float: right !important;
  font-size: 28px !important;
  font-weight: bold !important;
  position: absolute !important;
  right: 15px !important;
  font-weight: bold !important;
  z-index: 1000 !important;
  top: 5px;
}
.close-btn{
  color: #aaaaaa !important;
  font-size: 28px !important;
  font-weight: bold !important;
  z-index: 1000 !important;
  cursor: pointer;
}

.datarate_close-he-3 {
  color: #aaaaaa !important;
  float: right !important;
  font-size: 28px !important;
  left: 0px !important;
  top: -25px !important;
  font-weight: bold !important;
  z-index: 1000 !important;
  position: absolute;
}

.info-icon-2 {
  top: 18px !important
}

.datarate_close-3:hover,
.datarate_close-3:focus,
.datarate_close-he-3:hover,
.datarate_close-he-3:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

.datarate_modal .modal-content .data-rate-draggable {
  visibility: hidden !important;
}

.datarate_modal .modal-content:hover .data-rate-draggable {
  visibility: visible !important;
}

.people-marks {
  margin-top: 1rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700px;
  color: #C2C2C2;
  cursor: pointer;
  font-size: 14px;
}
.language-selector{
  max-width: fit-content !important;
}
.language-selector > div > div {
 width:fit-content !important;
}
.language-selector > div > div > div > div > input {
 display: none !important;
}
.language-selector > div > div > div {
min-width: 103%;
}
.align-items-center{
  align-items: center;
}
</style>
