<template>
  <div>
    <div class="main-section d-block">
      <div class="user-header d-block">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <div class="img-section" v-bind="attrs" v-on="on" :title="user.user_display_name">
              <img class="border-50" @error="imageLoadError" :src="user.profile_image_url" width="56" height="56"/>
              <!--                        <div>-->
              <!--                            <h4>{{ user.user_display_name }}</h4>-->
              <!--                            &lt;!&ndash; <h4>{{ user.name }}</h4> &ndash;&gt;-->

              <!--                        </div>-->
            </div>
          </template>
          <v-list>
            <v-list-item>
              <v-list-item-title><a href="https://www.app.datarate.io/profile" target="_blank"
                                    style="text-decoration:none"> Profile</a></v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title><a> Logout</a></v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!--          todo - return this "LVL" functionality when points system is working-->

        <!--            <div class="badge-lvl">-->
        <!--                <img :src="media_url +'badge.svg'" alt="">-->
        <!--                <span>lvl {{ user.level }}</span>-->
        <!--            </div>-->

      </div>

    </div>
  </div>
</template>

<script>
// import HTTP from "../../services/api"
import { base_url_media} from '../../../public/helper'

export default {
  name: "ProfileComponent",

  data: function () {
    return {
      user: {},
      media_url: base_url_media
    }
  },
  mounted() {
  //   if(localStorage.getItem("user_datarate")){
  //   HTTP.get(
  //       "/profile"
  //   )
  //       .then((response) => {
  //         if (response.data.error) {
  //           localStorage.removeItem("user_datarate");
  //           // window.location.reload();
  //         }
  //         this.user = response.data.profile
  //         this.$store.state.user = response.data.profile
  //         this.$emit("userData", this.user)
  //       })
  // }
  },
  methods: {
    logout() {
      this.$emit("logout", true)
    },
    imageLoadError(e) {
      e.target.src = base_url_media + "placeholder.png"
    },
  }
}
</script>

<style>
.user-header {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  margin-bottom: 15px !important;
}

.user-header .badge-lvl {
  width: 58px !important;
  height: 20px !important;
  background: rgb(18 141 111 / 10%) !important;
  border-radius: 25px !important;
  text-transform: uppercase !important;
  display: flex !important;
  align-items: center !important;
  margin-left: 30px !important;
}

.user-header .badge-lvl img {
  margin-right: 4px !important;
}

.user-header .badge-lvl span {
  font-size: 10px !important;
  font-weight: 500 !important;
  color: #128D6F !important;
}

.border-50 {
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
  margin-right: 10px !important;
}

.user-header .img-section {
  display: flex !important;
  align-items: center !important;
}

.user-header img {
  margin-right: 14px !important;
}

.user-header h2 {
  font-weight: bold !important;
  font-size: 20px !important;
  line-height: 24px !important;
  color: #262626 !important;
}

.user-header h4 {
  padding: 0 !important;
  margin: 0 !important;
}

.user-header p {
  font-weight: bold !important;
  font-size: 14px !important;
  color: rgba(38, 38, 38, 0.5) !important;
}


.v-list-item {
  cursor: pointer;
}

.v-list-item:hover {
  background: rgba(0, 0, 0, 0.08) !important;
}

.main-content .tag-section {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
  margin-bottom: 24px !important;
}

.main-content .tag-section > div {
  width: 103px !important;
  height: 36px !important;
  border-radius: 25px !important;
  background-color: #F8F8F8 !important;
  padding: 4px !important;
  display: flex !important;
  align-items: center !important;
}

.main-content .tag-section > div h5, .main-content .tag-section > div p {
  margin: 0 !important;
  padding: 0 !important;
  line-height: 14px !important;
}

.main-content .tag-section img {
  margin: 0 !important;
  padding: 0 !important;
  margin-right: 4px !important;
}

.main-content .tag-section h5 {
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: rgba(38, 38, 38, 0.5) !important;
  padding-bottom: 0px !important;
}

.main-content .tag-section p {
  font-weight: 500 !important;
  font-size: 14px !important;
  color: #262626 !important;
}

.related-search h2 {
  font-weight: bold !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #262626 !important;
  margin-bottom: 14px !important;
}

.main-content .tag-section .points h5, .main-content .tag-section .points p {
  margin: 0 !important;
  padding: 0 !important;
  line-height: 14px !important;
}

.datarate-search-box .v-text-field .v-label {
  top: 6px !important;
}
</style>
