<template>
  <div class="datarate_modal rate-font-family" :class="{'data-rate-hide':this.$store.state.disableModel}">

    <!-- Modal content -->
    <div class="modal-content" style="padding: 20px 20px 50px 20px !important;
">
      <span class="datarate_close" v-on:click="close">&times;</span>
      <p style="text-align: center;font-size: 20px;color:#262626;">{{$t("message.disableDataRate")}} <br><b style="color: #262626">{{$t("message.onDesign")}}</b></p>
      <div style="text-align: center">
        <button style=" background: #00CA99; border-radius: 25px;padding: 1.2em 4em;color: white;
    border: none;cursor: pointer;margin-top: 1em;text-transform: uppercase">{{$t("message.disableVisit")}}</button>

      </div>
      <div style="text-align: center">
        <button style="    background: #00CA99; border-radius: 25px;padding: 1.2em 6em;color: white;
    border: none;cursor: pointer;margin-top: 1em;text-transform: uppercase">{{$t("message.disableForever")}}</button>

      </div>
      <div style="text-align: center;margin-top: 2em">
        <a style="color: #128D6F;text-transform: uppercase;text-decoration: none" href="">{{$t("message.keepWorking")}}</a>

      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'disable',
  data: function () {
    return {}
  },
  methods: {
    close: function () {
      this.$store.state.disableModel = true;
    },


  }


}
</script>
<style>
/* The Modal (background) */
.datarate_modal {

  position: fixed !important; /* Stay in place */
  z-index: 1 !important; /* Sit on top */
  left: 0 !important;
  top: 0 !important;
  width: 100% !important; /* Full width */
  height: 100% !important; /* Full height */
  overflow: auto !important; /* Enable scroll if needed */
  /*background-color: rgb(0, 0, 0); !* Fallback color *!*/
  /*background-color: rgba(0, 0, 0, 0.4); !* Black w/ opacity *!*/
}

/* Modal Content */
.datarate_modal .modal-content {
  background-color: #fefefe !important;
  /*padding: 20px 20px 50px 20px !important;*/
  border: 1px solid #888 !important;
  width: 80% !important;
  box-shadow: 3px 6px 18px grey !important;
  border: none !important;
  margin-left: auto;
}

/* The Close Button */
.datarate_close {
  color: #aaaaaa !important;
  float: right !important;
  font-size: 28px !important;
  font-weight: bold !important;
}

.datarate_close:hover,
.datarate_close:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: pointer !important;
}
</style>

