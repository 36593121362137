import Vue from "vue";
import Vuex from "vuex";
import $ from "jquery";
import md5 from "md5";
import i18n from "../src/plugins/vue-i18n"
import {showStarSlider, allowPagination, hasSubArray} from "../public/helper"
import getElementByXpath, {
    showErrorMessage,
    markBlue,
    base_url_media,
} from "../public/helper";
import HTTP from "../src/services/api";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        addKeywords: {
            model: false,
        },
        currentLang:localStorage.getItem("datarate-user-language") ? localStorage.getItem("datarate-user-language") : navigator.language.split("-")[0] || navigator.userLanguage.split("-")[0],
        languages:[
            {name:"EN", lang:"en"},
            {name:"עברית", lang:"he"},
            {name:"ES", lang:"es"}
        ],
        local_storage_token_path:'',
        productEnabledFeatures: {
            rateParagraphButton: false,
            rightSearchButton: false,
            rightRateInformation: false,
            showPageParagraphStars: false,
            showPopup: false,
            showManagementSystemStar: false,
            showRecommendations: false,
            mediaRate: false,
            feedback: false,
            login: false,
            user_track_feedback: false
        },
        rate_type: "full",
        search: {
            model: false,
        },
        info: {
            model: false,
        },
        feedback: {
            model: false,
        },
        showAlert: false,
        user: localStorage.getItem("user_datarate")
            ? localStorage.getItem("user_datarate")
            : null,
        remarks: {
            model: false,
            imgSrc: "",
            htmlPattern: "",
            id: 0,
        },
        rating: {
            model: true,
            metadata: {
                content: "",
                page_url: "",
                page_id: "",
                website_url: "",
                media_url: "",
                media_id: "",
                css_path: "",
            },
            rate: {
                rate_points: 1,
                tags: [],
                dispay_only_five_tags: true,
            },
            suggestedTags: [],
            commentIcon: true,
            comment: "",
            object_id: null,
        },
        ratedObject: {
            pageRateMode: false,
            pageRate: 0,
            model: true,
            is_comment_hidden: false,
            is_button_disable: true,
            rating_response: [],
            dispay_only_five_tags: true,
            index: 0,
        },
        gradedObject: [],
        disableModel: true,
        successMessage: false,
        selectedObject_id: "",
        comments_list: [],
        feedback_comments_list: [],
        errorMessagePopUp: false,
        errorMessage: "",
        page_id: window.location.href.endsWith('#') || window.location.href.endsWith('/') ? md5(window.location.href.slice(0, window.location.href.length - 1)) : md5(window.location.href),
        page_url: window.location.href.endsWith('#') || window.location.href.endsWith('/') ? md5(window.location.href.slice(0, window.location.href.length - 1)) : md5(window.location.href),
        base_url: process.env.VUE_APP_BASE_URL,
    },
    actions: {
        searchModel({commit}, model) {
            commit("setSearch", model);
        },
        showAlert({commit}) {
            commit("setAlertModel");
        },
        ratingModel(context, payload) {
            let data = {content: payload.marked_text};
            if (payload.whole_page) {
                data["whole_page"] = payload.whole_page;
            }
            if (payload.page_id) {
                data["page_id"] = payload.page_id;
            }
            context.state.rating.metadata.page_id = window.location;
            context.state.rating.metadata["whole_page"] = payload.whole_page;
            if (payload.is_image) {
                context.state.rating.metadata['is_image'] = payload.is_image
                context.state.rating.metadata.media_url = payload.media_url
                context.state.rating.metadata.media_id = payload.media_id

            }
            if (payload.is_video) {
                context.state.rating.metadata['is_video'] = payload.is_video
                context.state.rating.metadata.media_url = payload.media_url
                context.state.rating.metadata.media_id = payload.media_id
            }


            if (payload.whole_page) {
                context.state.rating.metadata.css_path = "";
                context.state.rating.metadata.content = window.document.title;
                context.state.rating.metadata["whole_page"] = true;
            } else {
                markBlue(payload.csspath, payload.marked_text);
                context.state.rating.metadata.css_path = payload.csspath;
                context.state.rating.metadata.content = payload.marked_text;
            }
            if (payload.object_id != "") {
                context.state.rating.object_id = payload.object_id;
            } else {
                context.state.rating.object_id = null;
            }
            context.state.rating.metadata.page_url = window.location.origin;
            context.state.rating.metadata.page_id = window.location;
            context.state.rating.model = payload.ratingModel;
            context.state.rating.stars = 0;
            context.state.rating.rate.tags = [];
            context.state.rating.rate.dispay_only_five_tags = true;
            context.state.rating.commentIcon = true;
            context.state.rating.comment = "";
            context.state.ratedObject.is_button_disable = true;
            context.state.rating.is_loading_tags = true;
            context.state.rating.suggestedTags = [];

            HTTP.post("/tags", data)
                .then((response) => {

                    context.state.rating.suggestedTags = response.data.tags;
                    context.state.rating.is_loading_tags = false;

                })
                .catch((error) => {
                    context.state.rating.is_loading_tags = false;

                    // error = Error object,
                    if (error.response.data.status == 401) {
                        localStorage.removeItem("user_datarate");
                        // window.location.reload();
                    }
                    console.log("error mutation:", error.message);
                    console.log(error); // to se full error object
                    // showErrorMessage(context, error);
                });
        },

        rateObject(context, payload) {
            let url;
            let userData = localStorage.getItem("user_datarate");

            if (!userData) {
                url = context.state.base_url + "/rates/guests";
            } else {
                url = context.state.base_url + "/rates";
            }

            if (context.state.rating.object_id) {
                url = "/rates/" + context.state.rating.object_id + "/ratings";
            }
            let data = {
                ...context.state.rating,
                ...payload,
                last_urls:JSON.parse(localStorage.getItem("lastUrls"))
            }
            HTTP.post(url, data)
                .then((response) => {
                    context.state.rating = {
                        model: true,
                        metadata: {
                            content: "",
                            page_url: "",
                            page_id: "",
                            website_url: "",
                            media_url: "",
                            media_id: "",
                            css_path: "",
                        },
                        rate: {
                            rate_points: 1,
                            tags: [],
                            dispay_only_five_tags: true,
                        },
                        suggestedTags: [],
                        commentIcon: true,
                        comment: "",
                        object_id: null
                    }
                    // store.commit('removeGradedObjects');
                    // store.dispatch(
                    //     "getRatedObjects",
                    //     context.state.base_url + "/rates?page_id=" + context.state.page_url + '&rnd=' + (Math.floor((Math.random() * 1000) + 1)).toString()
                    // );

                    context.state.ratedObject.rating_response = response.data;
                    context.state.selectedObject_id = response.data.id;
                    context.state.remarks.htmlPattern = `<h3 class="heading">${i18n.t('message.rateSuccess')}</h3> <p class="paragraph-text">${i18n.t('message.receivedMessage')} <span class="text-green">${response.data.received_point}</span> ${i18n.t('message.pointsMessage')}</p>`;
                    context.state.rating.model = true;
                    context.state.remarks.model = true;
                    context.state.remarks.imgSrc = base_url_media + "avatar1.svg";

                    // context.state.successMessage = true;
                    setTimeout(function () {
                        context.state.ratedObject.model = false;
                        context.state.ratedObject.is_comment_hidden = false;
                        context.state.remarks.model = false;
                    }, 5000);
                })
                .catch((error) => {
                    // error = Error object,
                    if (error.response.data.status == 401) {
                        localStorage.removeItem("user_datarate");
                        // window.location.reload();
                    }
                    console.log("error mutation:", error.message);
                    console.log(error); // to se full error object
                    context.state.ratedObject.model = false;
                    context.state.ratedObject.is_comment_hidden = false;
                    context.state.remarks.model = false;
                });

        },
        getFeedbackTags(context) {
            let data = {content: 'Feedback'};
            data["whole_page"] = true
            HTTP.post("/tags", data)
                .then((response) => {

                    context.state.rating.suggestedTags = response.data.tags;
                    context.state.rating.is_loading_tags = false;

                })
                .catch((error) => {
                    context.state.rating.is_loading_tags = false;

                    // error = Error object,
                    if (error.response.data.status == 401) {
                        localStorage.removeItem("user_datarate");
                        // window.location.reload();
                    }
                    console.log("error mutation:", error.message);
                    console.log(error); // to se full error object
                    // showErrorMessage(context, error);
                });
        },
        addFeedback(context, payload) {
            let url;
            let userData = localStorage.getItem("user_datarate");

            if (!userData) {
                url = context.state.base_url + "/rates/guests";
            } else {
                url = context.state.base_url + "/rates";
            }

            if (context.state.rating.object_id) {
                url = "/rates/" + context.state.rating.object_id + "/ratings";
            }
            context.state.rating.comment = payload.comment
            context.state.rating.metadata = {
                content: payload.comment,
                name: payload.name,
                organization: payload.organziation,
                media_url: "",
                media_id: "",
                css_path: "",
                page_id: {
                    ancestorOrigins: {},
                    href: window.location.href,
                    origin: window.location.origin,
                    protocol: window.location.protocol,
                    host: window.location.host,
                    hostname: window.location.pathname,
                    port: window.location.port,
                    pathname: window.location.pathname,
                    search: window.location.search,
                    hash: window.location.hash
                },
                page_url: window.location.href,
                website_url: "",
                whole_page: true,
                is_feedback: true,
            }
            context.state.rating.object_id = null
            let data = {
                ...context.state.rating,
                ...payload,
                last_urls:JSON.parse(localStorage.getItem("lastUrls"))

            }
            HTTP.post(url, data)
                .then((response) => {
                    context.state.rating = {
                        model: true,
                        metadata: {
                            content: "",
                            page_url: "",
                            page_id: "",
                            website_url: "",
                            media_url: "",
                            media_id: "",
                            css_path: "",
                        },
                        rate: {
                            rate_points: 1,
                            tags: [],
                            dispay_only_five_tags: true,
                        },
                        suggestedTags: [],
                        commentIcon: true,
                        comment: "",
                        object_id: null
                    }
                    // store.commit('removeGradedObjects');
                    context.state.feedback.model = false
                    context.state.ratedObject.rating_response = response.data;
                    context.state.selectedObject_id = response.data.id;
                    context.state.remarks.htmlPattern = `<h3 class="heading">${i18n.t('message.feedbackMessage')}</h3>`;
                    context.state.remarks.model = true;
                    context.state.remarks.imgSrc = base_url_media + "avatar1.svg";
                    // context.state.successMessage = true;
                    setTimeout(function () {
                        context.state.feedback.model = false
                        context.state.remarks.model = false;
                    }, 5000);
                })
                .catch((error) => {
                    // error = Error object,
                    if (error.response.data.status == 401) {
                        localStorage.removeItem("user_datarate");
                        // window.location.reload();
                    }
                    context.state.feedback.model = false
                    context.state.remarks.model = false;
                    console.log("error mutation:", error.message);
                });

        },
        setWebsiteConfig(context, data) {
            console.log(data)
            context.state.productEnabledFeatures = {...data}
            context.state.token_info_url = data.token_info_url
            context.state.local_storage_token_path = data.local_storage_token_path
        },
        getSpecificRatedObject(context, index) {
            context.state.rate_type = "specific";
            let data_id;
            if (index) {
                if (index?.is_image || index?.is_video) {
                    context.state.selectedObject_id = index.id;
                    data_id = index.id
                    context.state.rating.metadata.css_path = index?.css_path
                    context.state.rating.metadata['media_url'] = index?.media_url
                    context.state.rating.metadata['media_id'] = index.id
                    if (index.is_image) {
                        context.state.rating.metadata['is_image'] = true
                    } else {
                        context.state.rating.metadata['is_video'] = true
                    }
                } else {
                    context.state.selectedObject_id = context.state.gradedObject[index].id;
                    data_id = context.state.gradedObject[index].id;
                }
                HTTP.get("/rates/" + data_id + "")
                    .then((response) => {
                        // store.commit('removeGradedObjects');
                        context.state.ratedObject.rating_response =
                            response.data.rated_object;
                        context.state.ratedObject.model = false;
                        context.state.ratedObject.is_comment_hidden = false;
                        context.state.ratedObject.is_button_disable = false;
                        context.state.ratedObject.dispay_only_five_tags = true;
                        context.state.ratedObject.index = index.is_image || index.is_video ? index.id : index;
                    })
                    .catch((error) => {
                        if (error.response.status === 403) {
                            context.state.ratedObject.rating_response = {}
                            context.state.ratedObject.model = false;
                            context.state.ratedObject.is_comment_hidden = false;
                            context.state.ratedObject.is_button_disable = false;
                            context.state.ratedObject.dispay_only_five_tags = true;
                            context.state.ratedObject.index = index.is_image || index.is_video ? index.id : index;
                        }
                        // error = Error object,
                        else {
                            if (error.response.data.status === 401) {
                                localStorage.removeItem("user_datarate");
                                // window.location.reload();
                            }
                            showErrorMessage(context, error);
                        }
                    });
            }
        },
        getRatedObjects(context, api) {
            HTTP.get(api)
                .then((response) => {
                    store.commit("removeGradedObjects");
                    context.state.gradedObject = response.data.rated_objects;

                    if (context.state.productEnabledFeatures.showPageParagraphStars) {

                        store.commit("addGradedObjects");

                    }
                    if (context.state.productEnabledFeatures.rightRateInformation) {
                        store.state.ratedObject.pageRate = response.data.page_general_rate.average_stars
                    }
                    store.state.ratedObject.pageRateResponse = response.data.page_general_rate
                })
                .catch((error) => {
                    if (error.response.data.status == 401) {
                        localStorage.removeItem("user_datarate");
                        // window.location.reload();
                    } else {
                        console.warn(error.response.data);
                    }
                });
        },
        getCommentsOfSelectedObject(context) {
            if (context.state.selectedObject_id) {
                HTTP.get("/rates/" + context.state.selectedObject_id + "/ratings")
                    .then((response) => {
                        context.state.comments_list = response.data.ratings;
                        context.state.selectedObject_id = "";
                    })
                    .catch(() => {
                        console.log("")
                        // error = Error object,
                        // if (error.response.data.status == 401) {
                        //     localStorage.removeItem("user_datarate");
                        //     window.location.reload();
                        // }
                        // showErrorMessage(context, error);
                    });
            } else {
                context.state.comments_list = [];
            }
        },
        changeLanguage(context, payload){
            i18n.locale = context.state.currentLang
            localStorage.setItem("datarate-user-language",context.state.currentLang)
            payload.languageSetter.rtl = context.state.currentLang === "he";
        },
        getCommentsOfFeedback(context) {
            if (context.state.ratedObject.pageRateResponse.id) {
                HTTP.get("/rates/" + context.state.ratedObject.pageRateResponse.id + "/ratings?is_feedback=true")
                    .then((response) => {
                        context.state.feedback_comments_list = response.data.ratings;
                        console.log(context.state.feedback_comments_list)
                    })
                    .catch((error) => {
                        console.log(error)
                        // error = Error object,
                        // if (error.response.data.status == 401) {
                        //     localStorage.removeItem("user_datarate");
                        //     window.location.reload();
                        // }
                        // showErrorMessage(context, error);
                    });
            } else {
                context.state.feedback_comments_list = [];
            }
        },
    },
    mutations: {
        setAlertModel: (state) => {
            state.showAlert = true;
        },
        setSearch: (state, model) => (state.search.model = model),
        removeGradedObjects(state) {
            document.querySelectorAll('.data-star-span').forEach(e => e.remove());
            for (var i = 0; i < state.gradedObject.length; i++) {
                $("#data-rate-start" + i + "").remove();

            }
        },
        addGradedObjects(state) {
            let existingStars = []
            state.gradedObject.map((item) => {


                const existingCSSPath = state.gradedObject.flatMap(
                    (elem, i) => elem.css_path === item.css_path ? i : []
                );
                if (existingCSSPath.length >= showStarSlider && allowPagination && !hasSubArray(existingStars, existingCSSPath)) {

                    existingStars = [...existingStars, ...existingCSSPath]
                    $(getElementByXpath(state.gradedObject[existingCSSPath[0]].css_path)).append(
                        `<span class="data-star-span v-application"> 
                        <button class="go-left mr-1 v-btn primary"> <img  src="https://d2kzr9hca36gxq.cloudfront.net/dist/images/right-arrow.svg" alt="data-rate-media"> </button>
                        ${existingCSSPath.map((star, index) => {
                            return `<img class="${index === 0 ? 'd-block' : 'd-none'}" id="data-rate-start${star}"
                            content-index="${star}" class="data-rate-graded data-rate-start" alt="data-rate-media" src="${base_url_media}stars.svg"/>`
                        }).join('')}
                        <button class="go-right ml-1 v-btn primary"> <img  src="https://d2kzr9hca36gxq.cloudfront.net/dist/images/right-arrow.svg" alt="data-rate-media"> </button>
                        </span>`
                    );
                }
            });
            for (var i = 0; i < state.gradedObject.length; i++) {
                if (existingStars.indexOf(i) === -1) {
                    let tempText = $(getElementByXpath(state.gradedObject[i].css_path))
                        .text()
                        .replace(/\s{2,}/g, " ")
                        .trim()
                        .replace(/\r?\n|\r/g, "");
                    let tempText2 = state.gradedObject[i].content
                        .replace(/\s\s+/g, " ")
                        .replace(/\r?\n|\r/g, "");
                    let text, text2;
                    tempText.length > tempText2.length
                        ? ((text = tempText), (text2 = tempText2))
                        : ((text = tempText2), (text2 = tempText));
                    if (text.includes(text2)) {
                        var margin = 0;
                        $(getElementByXpath(state.gradedObject[i].css_path))
                            .children("img")
                            .each(function () {
                                margin += 2.2;
                            });
                        $(getElementByXpath(state.gradedObject[i].css_path)).append(
                            `<img style="margin-left: ${margin}em; margin-left:${margin}" id="data-rate-start${i}"
             content-index="${i}" class="data-rate-graded data-rate-start" alt="data-rate-media" src="${base_url_media}stars.svg"/>`
                        );
                    }
                }
            }
        },
        addComment(state, value) {
            state.rating.comment = value;
        },
    },
});
export default store;
