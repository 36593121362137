<template>
<div class="datarate_modal rate-font-family modal-search">
    <!-- Modal content -->
    <div class="modal-content new-popup">
        <div class="modal-heading b-none">
            <div>
                <span  :class="this.$vuetify.rtl ? 'datarate_close-he' : 'datarate_close'" v-on:click="close">&times;</span>
            </div>
        </div>
        <!-- <stars></stars> -->
        <div class="main-content text-center">
            <img class="margin-bottom" :src="$store.state.remarks.imgSrc" alt="data-rate-media"/>
            <div v-html="$store.state.remarks.htmlPattern" class="f-16">

            </div>
        </div>
        <div class="modal-footer b-none">
        </div>
    </div>
</div>
</template>

<script>
import HTTP from '../../services/api';
import {showErrorMessage} from '../../../public/helper'

export default {
    name: "RemarksComponent",
    props: {
        imgSrc: {
            type: String,
            default: ""
        },
        htmlData: {
            type: String,
            default: ""
        },
        currentLang: {
      type: String,
      default: "en",
    },
    },

    data: function () {
        return {
            items: [],
            values: [],
            results: [],
            defaultChips: [
                "instruction",
                "tutorial",
                "learning",
                "training",
                "tution",
                "online learning",
            ],
        };
    },
    methods: {
        close: function () {
            this.$store.state.remarks.model = false;
        },
        addChip: function (item) {
            if (this.values?.indexOf(item) === -1 || this.items?.indexOf(item) === -1) {
                this.items.push(item);
                this.values.push(item);
            }
        },
    },
    watch: {
        values(val) {
            if (val?.length > 0) {
                let params = "";
                val.map((item, index) =>
                    index === 0 ? (params = item) : (params += "," + item)
                );
                HTTP
                    .get("/rates?tags=" + params)
                    .then((response) => {
                        this.results = response.data.rates;
                    }).catch((error) => {
                        if (error.response.data.status == 401) {
                            localStorage.removeItem("user_datarate");
                            // window.location.reload();
                        }
                        else{
                            showErrorMessage(this.$store, error)
                        }
                    })
            } else if (val?.length === 0) {
                this.results = []
            }
        },
    },

};
</script>

<style>
/* The Modal (background) */
.datarate_modal {
    position: fixed !important;
    /* Stay in place */
    z-index: 1 !important;
    /* Sit on top */
    /* Location of the box */
    left: 0 !important;
    top: 0 !important;
    width: 100% !important;
    /* Full width */
    height: 100% !important;
    /* Full height */
    overflow: auto !important;
    /* Enable scroll if needed */
    /*background-color: rgb(0, 0, 0) !important; !* Fallback color *!*/
    /*background-color: rgba(0, 0, 0, 0.4); !* Black w/ opacity *!*/
    z-index: 9999999 !important;
    overflow-x: hidden !important;
}

.margin-bottom {
    margin-bottom: 8px !important;
}

.paragraph-text {
    margin-top: 23px !important;
    color: rgba(38, 38, 38, 0.7) !important;
    font-weight: 500 !important;
    line-height: 24px !important;
}

.heading {
    font-size: 18px !important;
    font-weight: 500 !important;
}

.text-green {
    color: #138D6F !important;
}

.margin-top-24 {
    margin-top: 24px;
}

.margin-left-14 {
    margin-left: 14px;
}

.margin-top-neg-14 {
    margin-top: -14px;
}

.d-flex {
    display: flex;
}

.b-none {
    border: none !important;
}

.modal-search {
    padding-top: 0px !important;
}

/* Modal Content */
.datarate_modal .modal-content {
    padding: 24px 28px !important;
    border: 1px solid #888 !important;
    width: 420px !important;
    border-radius: 20px !important;
    box-shadow: 0px 5px 26px 4px rgba(0, 0, 0, 0.05),
        0px 7px 9px -4px rgba(0, 0, 0, 0.1) !important;
    border: none;
}

.datarate_modal .modal-content.new-popup {
    padding: 24px 0px !important;
    width: 410px !important;
}

.main-cards {
    height: 250px !important;
    overflow-y: auto !important;
    padding-right: 6px !important;
}

.datarate_modal .modal-content.new-popup .main-content {
    padding: 0px 28px !important;
}

/* Card scroll */
.main-cards::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
}

.main-cards::-webkit-scrollbar-thumb {
    background-color: #f3f3f3 !important;
}

.yellow--text.text--darken-3 {
    color: #f9a825 !important;
    caret-color: #f9a825 !important;
}

.grey--text.text--darken-1 {
    color: #757575 !important;
    caret-color: #757575 !important;
}

/* The Close Button */
.datarate_close {
    color: #aaaaaa !important;
    position: absolute !important;
    right: 15px !important;
    font-size: 28px !important;
    font-weight: bold !important;
    z-index: 1000 !important;
    top:-14px
}
.datarate_close-he {
    color: #aaaaaa !important;
    position: absolute !important;
    left: 15px !important;
    font-size: 28px !important;
    font-weight: bold !important;
    z-index: 1000 !important;
    top:-25px
}

.datarate_close:hover,
.datarate_close:focus,.datarate_close-he:hover,
.datarate_close-he:focus {
    color: #000 !important;
    text-decoration: none !important;
    cursor: pointer !important;
}

.datarate_modal .modal-content.new-popup .data-rate-draggable {
    visibility: hidden !important;
}

.datarate_modal .modal-content.new-popup:hover .data-rate-draggable {
    visibility: visible !important;
}

.d-none {
    display: none !important;
}

.text-mark-bg {
    background: lightgreen !important;
}

.new-popup .modal-heading {
    position: relative !important;
    font-weight: 700 !important;
    font-size: 20px !important;
    color: #262626 !important;
    /* display: flex !important; */
    align-items: center !important;
    justify-content: space-between !important;
    padding-left: 28px !important;
    padding-right: 28px !important;
    padding-bottom: 24px !important;
}

/* .new-popup .modal-heading div {
    display: flex !important;
    align-items: center !important;
} */

.new-popup .modal-heading h4 {
    padding-left: 4px !important;
    padding-right: 4px !important;
}

.new-popup .modal-footer {
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    flex-wrap: wrap !important;
    padding-left: 12px !important;
    padding-right: 12px !important;
    padding-top: 24px !important;
    margin-top: 24px !important;
}

.new-popup .modal-footer .v-btn {
    color: #939393 !important;
    background: #fff !important;
    box-shadow: unset;
    text-transform: inherit;
    padding: 0 !important;
}

.new-popup .modal-footer .v-btn {
    color: #939393 !important;
    background: #fff !important;
    box-shadow: unset !important;
    text-transform: inherit !important;
    padding: 0 !important;
    font-size: 12px;
}

.new-popup .modal-footer .dangerous {
    color: #939393 !important;
}

.new-popup .modal-footer .color-green {
    color: #00ca99 !important;
}

.modal-footer .color-green .v-icon {
    margin-right: 2px !important;
}

.modal-footer .share,
.modal-footer .save {
    min-width: 16px !important;
}

.modal-footer .share img,
.modal-footer .save img {
    margin-right: 4px !important;
}

.rate-btn {
    margin-top: 1em !important;
    text-align: center !important;
}

.rate-btn .v-btn {
    background: #00ca99 !important;
    border-radius: 25px !important;
    padding: 1.1em 4em !important;
    border: none !important;
    cursor: pointer !important;
    color: white !important;
}

.movable {
    position: relative !important;
    top: unset !important;
    left: unset !important;
    height: 20px !important;
    width: 100% !important;
}

.modal-tags .tag-input input {
    line-height: 40px !important;
}

.modal-tags .tag-input .tag-input__tag {
    margin-top: 0 !important;
    padding: 4px 12px !important;
}

.ml-2 {
    margin-left: 10px;
}

.mr-1 {
    margin-right: 4px;
}

.mb-2 {
    margin-bottom: 10px;
}

.modal-tags .tag-input .tag-input__tag>span {
    opacity: 1 !important;
    margin-right: 6px !important;
}

.modal-tags .tag-input .tag-input__tag>span:last-child {
    margin-right: 0 !important;
}

.new-popup .card-header {
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    flex-wrap: wrap !important;
    margin-bottom: 5px !important;
}

.new-popup .card-header .title {
    font-size: 16px !important;
    color: #262626 !important;
    font-weight: bold !important;
    padding: 0 !important;
}

.new-popup .card-header a {
    color: #128d6f !important;
    font-size: 12px !important;
    font-weight: bold !important;
}

.new-popup .v-card {
    box-shadow: unset !important;
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    border-radius: 10px !important;
    padding: 24px 24px 5px 24px !important;
    margin-bottom: 8px !important;
}

.new-popup .v-card .mdi-star::before {
    color: #ffcc48;
}

.mdi-star-outline::before {
    color: lightgray;
    content: "\F04CE";
}

.new-popup {
    position: absolute !important;
    right: 0 !important;
}

.new-popup .v-card .rating-row {
    margin-bottom: 10px !important;
}

.new-popup .v-card .v-rating .v-icon {
    padding: 1px 2px !important;
    font-size: 20px !important;
}

.new-popup .v-card .v-rating .v-icon:last-child {
    margin-right: 9px !important;
}

.new-popup .v-card .grey--text {
    color: #262626 !important;
    font-size: 18px !important;
    font-weight: bold !important;
}

.new-popup .v-card .grey--text span {
    color: rgba(38, 38, 38, 0.5) !important;
    font-size: 12px !important;
    font-weight: normal !important;
}

.new-popup .v-card .grey--text .based-on {
    margin-left: 14px !important;
}

.new-popup .v-card .rating-section {
    padding: 10px !important;
    padding-top: 15px !important;
    padding-bottom: 0px !important;
}

.v-chip {
    background-color: #f3f3f3 !important;
}

.new-popup .v-card .v-chip-group .v-chip--active {
    border: 2px solid #00ca99 !important;
    background-color: #fff !important;
    border-radius: 15px !important;
    color: #262626 !important;
}

.new-popup .v-card .v-chip--active:hover::before,
.new-popup .v-card .v-chip--active::before,
.new-popup .v-card .v-chip--active:active {
    opacity: 0 !important;
}

.new-popup .v-card .rated-object {
    color: #2196f3 !important;
    text-transform: inherit !important;
    font-weight: bold !important;
    font-size: 14px !important;
    letter-spacing: normal !important;
    padding: 0 !important;
    opacity: 1 !important;
}

.new-popup .v-card .v-card__subtitle,
.new-popup .v-card .v-card__text,
.new-popup .v-card .v-card__title {
    padding: 3px !important;
}

.new-popup .modal-footer .v-btn .v-icon,
.new-popup .modal-footer .v-btn img {
    margin-right: 6px !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}

.datarate_modal .modal-content.new-popup .mdi-menu-down {
    display: none !important;
}

.card-desc {
    overflow: hidden !important;
    width: 318px !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    text-overflow: ellipsis !important;
    color: rgba(38, 38, 38, 0.87) !important;
    font-size: 16px !important;
    margin-top: 12px !important;
}

.new-popup .v-card .chat-views {
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
    flex-wrap: wrap !important;
}



.new-popup .v-card .chat-views .v-btn {
    color: #128d6f !important;
    background: transparent !important;
    box-shadow: unset !important;
    padding: 0 7px !important;
    opacity: 0.7 !important;
    font-size: 14px !important;
}

.new-popup .v-card .chat-views .v-btn .v-btn__content {
    justify-content: space-between !important;
}

.v-slide-group__next,
.v-slide-group__prev {
    min-width: auto !important;
    flex: 0 !important;
}

@media (max-width: 575px) {
    .card-desc {
        width: 220px !important;
    }
}

@media (max-width: 430px) {
    .datarate_modal .modal-content.new-popup {
        width: 100% !important;
    }

    .new-popup .modal-footer {
        padding-left: 6px !important;
        padding-right: 6px !important;
    }
}
</style>
