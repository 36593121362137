<template>
  <v-app>
    <div>
      <div
          class="data-rate-dot dara-rate-property"
          ref="parentEl"
          v-if="$store.state.productEnabledFeatures.rateParagraphButton"
      >
        <movable
            style="position: relative; width: 100%; height: 2px"
            target="parentEl"
        >
        </movable>
        <!--        <img class="data-rate-poweroff dara-rate-property" style="display: inline-block"-->
        <!--             :src="media_url +'poweroff.png'" alt="" v-on:click="powerOff"/>-->
        <v-menu offset-x bottom="$false">
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on" style="display: inline-block">
              <img
                  class="data-rate-poweroff dara-rate-property"
                  :src="media_url + '3-vertical-dots.svg'"
                  alt="data-rate-media"
              />
            </div>
          </template>

          <v-list>
            <v-list-item @click="powerOff">
              <v-list-item-title>
                <img
                    class="data-rate-poweroff dara-rate-property"
                    style="display: inline-block"
                    :src="media_url + 'poweroff.png'"
                    alt="data-rate-media"
                />
                <a style="margin-bottom: 5px; color: #b3b3b3 !important">
                  {{ $t("message['Power Off']") }}</a
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <!--            <img class="data-rate-warning dara-rate-property"
         style="display: inline-block" v-on:click="warning" :src="media_url + 'warning.png'" alt="" />-->
        <img
            v-on:click="stars(selectedText, css_path)"
            style="display: inline-block"
            class="data-rate-stars dara-rate-property"
            :src="media_url + 'stars.svg'"
            alt="data-rate-media"
        />
        <movable
            style="position: relative; width: 100%; height: 2px"
            target="parentEl"
        >
        </movable>
      </div>

      <disable></disable>
      <ratingDialoge :currentLang="$store.state.currentLang"></ratingDialoge>
      <successMessage></successMessage>
      <model :currentLang="$store.state.currentLang" v-if="!this.$store.state.ratedObject.model"></model>
      <gradingStar></gradingStar>
      <errorMessage></errorMessage>

      <FeedbackDialog
          :currentLang="$store.state.currentLang"
          v-if="$store.state.feedback.model"></FeedbackDialog>
      <add-keywords v-if="$store.state.addKeywords.model"></add-keywords>
      <search-component
          :show-profile="showProfile"
          :currentLang="$store.state.currentLang"
          v-if="$store.state.search.model && $store.state.rating.model"
      ></search-component>
      <transition name="slide-fade">
        <remarks-component
            :html-data="htmlData"
            :currentLang="$store.state.currentLang"
            v-if="$store.state.remarks.model"
        ></remarks-component>
      </transition>
      <transition name="slide-fade">
        <InfoModal v-if="$store.state.info.model" :currentLang="$store.state.currentLang"/>
      </transition>

      <v-btn
          v-if="
          !$store.state.search.model &&
          $store.state.rating.model &&
          $store.state.productEnabledFeatures.rightRateInformation
        "
          class="star-datarate-btn primary"
          type="button"
          style="margin-top: 180px"
          @click="pageRateOpen"
      >
        {{
          $store.state.ratedObject ? $store.state.ratedObject.pageRate : "0"
        }}/5

        <v-icon>mdi-star</v-icon>
      </v-btn>
      <!-- <v-btn v-if="$store.state.productEnabledFeatures.feedback"
             style="background-color: #0db7c4 !important;"
             ref="feedbackBtn" type="button" class="feedback-datarate-btn secondary"
             @click="$store.state.feedback.model = true"
             @mouseover.native="hoverFeedbackButton()"
             @mouseout.native="unhoverFeedbackButton()"
      >
        <v-icon>mdi-chat</v-icon>
      </v-btn>  -->
      <div style="z-index: 10000;" class="feedback-datarate-btn d-flex" v-if="$store.state.productEnabledFeatures.feedback"  ref="feedbackBtn"
      @click="$store.state.feedback.model = true"
             @mouseover="hoverFeedbackButton()"
             @mouseout="unhoverFeedbackButton()"
      >
        <v-icon class="white--text mr-1">mdi-chat</v-icon>
    <span>        {{ $t("message.feedbackButton") }}</span>
</div>
      <v-btn
          v-if="
          !$store.state.search.model &&
          $store.state.rating.model &&
          $store.state.productEnabledFeatures.rightSearchButton
        "
          class="search-datarate-btn primary"
          type="button"
          @click="
          $store.state.search.model = true;
          // $store.state.pageRate.model = true;
          showProfile = true;
        "
      >
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <br/><br/>
      <!--        <v-btn type="button" @click="-->
      <!--          $store.state.search.model = true;-->
      <!--          showProfile = false;-->
      <!--        ">Testing Modal-->
      <!--        </v-btn>-->
      <!--        <br /><br />-->
      <!--        <v-btn type="button" @click="setPopup1">Show Remarks Popup with First Image-->
      <!--        </v-btn>-->
      <!--        <br /><br />-->
      <!--        <v-btn type="button" @click="setPopup2">Show Remarks Popup with Second Image-->
      <!--        </v-btn>-->
      <!--        <br /><br />-->
      <!--        <v-btn type="button" @click="setPopup3">Show Remarks Popup with Third Image-->
      <!--        </v-btn>-->
      <!--        <br /><br />-->
      <!--        <v-btn type="button" @click="$store.state.addKeywords.model = true">Show Add Keywords Popup {{$store.state.showAlert}}-->
      <!--        </v-btn>-->
      <!--        <br /><br />-->
    </div>
    <v-alert type="error" v-if="$store.state.showAlert">
      {{ $t("message['You must']") }}
      <a
          :href="
          'https://app.datarate.io/login?is_extension=true&redirect_url=' +
          redirectDomain
        "
          class="white--text text-decoration-underline"
      >{{ $t("loginregister") }}</a
      >
      {{ $t("message['To Rate Information']") }}
    </v-alert>
  </v-app>
</template>

<script>
import $ from "jquery";
import disable from "./components/disable";
import ratingDialoge from "./components/StarComponent/ratingDialoge";
import successMessage from "./components/SuccessMessage/successMessage";
import store from "../store";
import SearchComponent from "./components/SearchComponent/SearchComponent";
import RemarksComponent from "./components/RemarksComponent/RemarksComponent";
import model from "./components/ratedObjectComponent/ratedObjectModel";
import gradingStar from "./components/GradedObjectComponent/gradingStar";
import errorMessage from "./components/errorMessage/errorMessage";
import addKeywords from "./components/AddKeywords/addKeywords";
import InfoModal from "./components/Info/info.vue";
import {showErrorMessage, base_url_media} from "../public/helper";
import HTTP from "./services/api";
import {markBlue, unmarkBlue} from "../public/helper";
import getElementByXpath, {getElementsXpath} from "../public/helper";
import md5 from "md5";
import FeedbackDialog from "./components/FeedbackComponent"
import i18n from "./plugins/vue-i18n";

const asyncLocalStorage = {
  setItem: function (key, value) {
    return Promise.resolve().then(function () {
      localStorage.setItem(key, value);
    });
  },
  getItem: function (key) {
    return Promise.resolve().then(function () {
      return localStorage.getItem(key);
    });
  },
};
export default {
  name: "App",
  components: {
    disable,
    ratingDialoge,
    model,
    gradingStar,
    errorMessage,
    SearchComponent,
    RemarksComponent,
    successMessage,
    addKeywords,
    InfoModal,
    FeedbackDialog
  },
  data: function () {
    return {
      selectedText: "",
      previousText: "",
      css_path: "",
      redirectDomain: "",
      htmlData: "",
      media_url: base_url_media,
      pageRate: {model: false},
      feedBackTimer: null
    };
  },
  computed: {
    loginWithWebsiteAccount() {
      return this.$store.state.productEnabledFeatures.login_with_website_account;
    }
  },
  watch: {
    loginWithWebsiteAccount(loginWithWebsiteAccountOld, loginWithWebsiteAccountNew) {
      if (loginWithWebsiteAccountNew) {
        return
      }
      // This function will be called whenever the store variable changes
      // localStorage.getItem('domain')
      // let splittedTokenPath = newLocalStorageTokenPath.split('__')
      // console.log('external auth token:')
      // let externalToken = this.getLocalStorageValueByPath(splittedTokenPath)

      // let profileUrl = '/profile'
      // this.$store.state.externalToken = externalToken;
      //
      // if (externalToken) {
      //   console.log('profile')
      //   console.log(profileUrl)
      //   if (profileUrl) {
      //     console.log('in profile if')
      //     axios.get(profileUrl,{
      //       headers: {
      //         Authorization: 'Bearer '+externalToken
      //       }
      //     }).then((resp) => {
      //       console.log('then')
      //       console.log(resp)
      //       this.$store.state.externalLoginIsRequired = false;
      //       this.$store.state.userLoginnedExternal = true;
      //       this.$store.state.userName = resp.user_name;
      //       this.$store.state.firstName = resp.first_name;
      //       this.$store.state.lastName = resp.last_name;
      //       this.$store.state.email = resp.email;
      //     })
      //   }
      //
      //
      // }
      // You can add your custom logic here
    }
  },

  mounted() {
    setInterval(() => {
      this.targetMedia()
    }, 500)
    this.getWebsiteConfig()
    this.$vuetify.rtl = this.$store.state.currentLang === "he";
    i18n.locale = this.$store.state.currentLang
    let indexHtml = getElementByXpath("/html");
    indexHtml.style.height = "auto";
    this.redirectDomain = window.location.href;
    // localStorage.setItem('user_datarate', 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImFwaV9rZXkiOiJZa05pWklZQlQ2VXMwN3hoS2FBSCJ9.eyJlbWFpbCI6InppdnNAcGljYWZ1ZWwuY29tIiwiZmlyc3RfbmFtZSI6InppdiIsImxhc3RfbmFtZSI6InNhcnVzaSJ9.NzG6iE9ZKoWvtxaLNuNSfPVLXH_NnRI2PBp18bUpyr4')
    // localStorage.setItem('datarate_token', '')
    // localStorage.setItem('user_datarate', '')
    // let datarateAuthorized = localStorage.getItem('datarate_authorized') === "true"
    // localStorage.setItem("authorized_external", false)

    // }).catch((err) => {
    //   showErrorMessage(this.$store, err)
    // })

    if (window.location.hash) {
      let access_token = window.location.hash
          .split("#")[1]
          .split("access_token=")[1];
      let accessTokenStartIdx = window.location.hash.indexOf("#access_token=");


      if (access_token) {
        if(window.location.host.includes('datarate.io'))
        {
          asyncLocalStorage.setItem("user_datarate", access_token).then(() => {
            window.location.hash = window.location.hash.slice(
                0,
                accessTokenStartIdx
            );
            // window.location.reload();
          });
        }
      }

    }

    // setInterval(() => {
    //   this.getData();
    // }, 60000);

    // document.addEventListener("touchend", (event) => {
    //     const selection = document.getSelection();
    //     this.selectedText = selection.toString();
    //     if (window.getSelection().anchorNode.parentElement && $(window.getSelection().anchorNode.parentElement).hasClass("dara-rate-property") != true) {
    //         $(".data-rate-dot").css("display", "none");
    //     } else {
    //         return false;
    //     }
    //     if(window.getSelection().anchorNode.parentElement){
    //     var b = getXPath(window.getSelection().anchorNode.parentElement)
    //     }
    //     //add dot when we have selected text
    //     if (this.selectedText != "" && this.selectedText != this.previousText) {
    //         this.previousText = this.selectedText;
    //         this.css_path = b;
    //         var positionFromLeft = event.layerX;
    //         var positionFromTop = event.layerY;
    //         $(".data-rate-dot").css("left", positionFromLeft);
    //         $(".data-rate-dot").css("top", positionFromTop);
    //         $(".data-rate-dot").css("display", " inline-block");
    //     }

    //     function getXPath(element) {
    //         var xpath = "";
    //         for (; element && element.nodeType == 1; element = element.parentNode) {
    //             var id =
    //                 $(element.parentNode).children(element.tagName).index(element) + 1;
    //             id > 1 ? (id = "[" + id + "]") : (id = "[1]");
    //             xpath = "/" + element.tagName.toLowerCase() + id + xpath;
    //         }
    //         return xpath;
    //     }
    // })

    document.addEventListener("mouseup", (event) => {
      const selection = document.getSelection();
      this.selectedText = selection.toString();
      try {
        if (
            $(window.getSelection().anchorNode.parentElement).hasClass(
                "dara-rate-property"
            ) != true
        ) {
          $(".data-rate-dot").css("display", "none");
        } else {
          return false;
        }
      } catch (ERROR) {
        return false;

      }

      var b = getXPath(window.getSelection().anchorNode.parentElement);
      //add dot when we have selected text
      if (this.selectedText != "" && this.selectedText != this.previousText) {
        this.previousText = this.selectedText;
        this.css_path = b;
        var positionFromLeft = event.clientX;
        var positionFromTop = event.pageY;
        // console.log("x");
        // console.log(event.clientX);
        // console.log(" page y");
        // console.log(event.pageY);
        // console.log("clieny y");
        // console.log(event.clientY);
        $(".data-rate-dot").css("left", positionFromLeft);
        $(".data-rate-dot").css("top", positionFromTop);
        $(".data-rate-dot").css("display", " inline-block");
      }

      function getXPath(element) {
        var xpath = "";
        for (; element && element.nodeType == 1; element = element.parentNode) {
          var id =
              $(element.parentNode).children(element.tagName).index(element) + 1;
          id > 1 ? (id = "[" + id + "]") : (id = "[1]");
          xpath = "/" + element.tagName.toLowerCase() + id + xpath;
        }
        return xpath;
      }
    });

    // function getElementByXpath(path) {
    //     return document.evaluate(
    //         path,
    //         document,
    //         null,
    //         XPathResult.FIRST_ORDERED_NODE_TYPE,
    //         null
    //     ).singleNodeValue;
    // }

    var store = this.$store;
    $(document).on("mouseover click", ".data-rate-graded", function (event) {
      $(event.target).attr("event", event.handleObj.type);
      var index = $(event.target).attr("content-index");
      markBlue(
          store.state.gradedObject[index].css_path,
          store.state.gradedObject[index].content
      );
    });
    $(document).on("mouseleave", ".data-rate-graded", function (event) {
      if ($(event.target).attr("event") === "mouseover") {
        var index = $(event.target).attr("content-index");
        unmarkBlue(
            store.state.gradedObject[index].css_path,
            store.state.gradedObject[index].content
        );
      }
    });

    let lastUrl = location.href;

    new MutationObserver(() => {
      const url = location.href;
      let pageId = md5(url)
      if (url.endsWith("/#")) {
        pageId = md5(
            url.slice(0, url.length - 2))

      }
      if (url.endsWith("/") && !url.endsWith("#")) {
        pageId = md5(
            url.slice(0, url.length - 1))

      }


      if (url !== lastUrl) {
        lastUrl = url;

        let pageUrl =
            window.location.href.endsWith("#") ||
            window.location.href.endsWith("/")
                ? md5(
                    window.location.href.slice(0, window.location.href.length - 1)
                )
                : md5(window.location.href);
        store.state.page_id = pageId;
        store.state.page_url = pageUrl;
        // store.dispatch(
        //     "getRatedObjects",
        //     "/rates?page_id=" +
        //     pageId +
        //     "&rnd=" +
        //     Math.floor(Math.random() * 1000 + 1).toString()
        // );
      }
    }).observe(document, {subtree: true, childList: true});

    // document.addEventListener('mouseover', event => {
    //   // console.log($(event.target).hasClass('data-rate-graded'))
    //   if($(event.target).hasClass('data-rate-graded')){
    //     var index=$(event.target).attr('content-index');
    //     console.log(this.$store.state.gradedObject[index])
    //     console.log(index)
    //    var string= $(getElementByXpath(this.$store.state.gradedObject[index].css_path)).html();
    //     var newText=string.replace(this.$store.state.gradedObject[index].content,
    //         "<span style='color: blue'>"+this.$store.state.gradedObject[index].content+"</span> ");
    //     $(getElementByXpath(this.$store.state.gradedObject[index].css_path)).empty();
    //     $(getElementByXpath(this.$store.state.gradedObject[index].css_path)).append(newText)
    //   }
    // });
    // document.addEventListener('mouseleave', event => {
    //   console.log('here')
    //   // console.log($(event.target).hasClass('data-rate-graded'))
    //   if($(event.target).hasClass('data-rate-graded')){
    //     var index=$(event.target).attr('content-index');
    //     console.log(this.$store.state.gradedObject[index])
    //     console.log(index)
    //     var string= $(getElementByXpath(this.$store.state.gradedObject[index].css_path)).html();
    //     var newText=string.replace("<span style='color: blue'>"+this.$store.state.gradedObject[index].content+"</span> ",
    //         this.$store.state.gradedObject[index].content);
    //     $(getElementByXpath(this.$store.state.gradedObject[index].css_path)).empty();
    //     $(getElementByXpath(this.$store.state.gradedObject[index].css_path)).append(newText)
    //   }
    // });

    // store.dispatch(
    //     "getRatedObjects",
    //     "/rates?page_id=" +
    //     store.state.page_id +
    //     "&rnd=" +
    //     Math.floor(Math.random() * 1000 + 1).toString()
    // );

  },
  methods: {
    getAccessTokenBy(path) {
      let value = localStorage.getItem(path[0]);
      for (let i = 1; i < path.length; i++) {
        value = JSON.parse(value)[path[i]];
      }
      return value;
    },
    getLocalStorageValueByPath(path) {
      let value = localStorage.getItem(path[0]);
      if (value) {
        value = JSON.parse(value);
        for (let i = 1; i < path.length; i++) {
          value = value[path[i]];
          if (!value) {
            break;
          }
        }
      }
      return value;
    },
    hoverFeedbackButton() {
      this.feedBackTimer = setTimeout(() => {
        console.log(this.$refs.feedbackBtn)
        this.$refs.feedbackBtn.style.opacity = 0
      }, 1000);
    },
    unhoverFeedbackButton() {
      clearTimeout(this.feedBackTimer)
      this.$refs.feedbackBtn.style.opacity = 1
    },
    getWebsiteConfig() {
      let isExtension = (document.getElementsByName('datarate-plugin-source') && document.getElementsByName('datarate-plugin-source')[0]) ? document.getElementsByName('datarate-plugin-source')[0].dataset.source === 'extension' : false
      HTTP.get(`/websites/${document.querySelector("meta[data-api-key]").getAttribute('data-api-key')}`)
          .then((response) => {
            if (response.data.error && !isExtension) {
              localStorage.removeItem("user_datarate");
              console.warn('Website not exist in Datarate system')
              store.dispatch('setWebsiteConfig', {
                rateParagraphButton: false,
                rightSearchButton: false,
                rightRateInformation: false,
                showPageParagraphStars:false,
                showPopup: false,
                showManagementSystemStar: false,
                showRecommendations: false,
                mediaRate: false,
                feedback: false,
                login: false,
                user_track_feedback: false,
                token_info_url: false,
                login_with_website_account: false
              });
              return
              // window.location.reload();
            }
            const config = response.data.website.active_features
            const token_info_url = response.data.website.token_info_url
            const login_with_website_account = config.login_with_website_account
            store.dispatch('setWebsiteConfig', {
              rateParagraphButton: config.rate_paragraph_button,
              rightSearchButton: config.right_search_button,
              rightRateInformation: config.right_rate_information,
              showPageParagraphStars: config.show_pageParagraphStars,
              showPopup: config.show_popup,
              showManagementSystemStar: config.show_management_system_star,
              showRecommendations: config.show_recommendations,
              mediaRate: config.media_rate,
              feedback: config.feedback,
              login: config.login,
              user_track_feedback: config.user_track_feedback,
              token_info_url: token_info_url,
              login_with_website_account: login_with_website_account,
            });
          })
          .catch((error) => {
            // error = Error object,
            if (error.response.data.status == 401 && !isExtension) {
              localStorage.removeItem("user_datarate");
              // window.location.reload();
            }
            console.warn('Website not exist in Datarate system')
          });
    },
    isExcludedCssPath(cssPath) {
      return cssPath === "/html/body/div[2]/div/div[3]/div/header/div/div[2]/div[2]/div/div/div/div/div/div/a/wix-image/img"
    },
    targetMedia() {
      let isExtension = (document.getElementsByName('datarate-plugin-source') && document.getElementsByName('datarate-plugin-source')[0]) ? document.getElementsByName('datarate-plugin-source')[0].dataset.source === 'extension' : false
      if (!this.$store.state.productEnabledFeatures.mediaRate) {
        return
      }
      let mediaData
      // if (document.querySelectorAll("picture").length > 0) {
      //   mediaData = document.querySelectorAll("picture")
      // } else {
      //   mediaData = document.querySelectorAll("img:not([alt='data-rate-media']")
      // }
      let customStarsPositions = document.querySelectorAll(`[data-datarateitem="true"]`);
      if (customStarsPositions) {
        mediaData = customStarsPositions

      }

      mediaData.forEach((item, index) => {
        let cssPath = getElementsXpath(item)
        if (!item.parentElement.querySelector(".data-rate-media") && !this.isExcludedCssPath(cssPath)) {
          if (!customStarsPositions) {
            item.parentElement.classList.add('data-rate-media')
          } else {
            item.classList.add('data-rate-media')

          }
          var image = document.createElement('img');
          image.src = `${base_url_media}stars.svg`;
          image.style.cursor = 'pointer';
          image.style.position = 'absolute';
          image.alt = "data-rate-media"
          image.classList.add('data-rate-media')
          image.style.zIndex = "99999999"
          if (isExtension) {
            image.style.opacity = 0
            item.onmouseleave = () => {
              image.style.opacity = 0
            }
            item.onmouseenter = () => {
              image.style.opacity = 1
            }
            image.onmouseenter = () => {
              image.style.opacity = 1

            }
            image.onmouseleave = () => {
              image.style.opacity = 0

            }

          }
          image.id = 'star-' + index
          if (item.tagName === 'PICTURE') {
            image.addEventListener("mouseenter", () => {
              image.parentElement.classList.add("remove-animation")
            })
            image.addEventListener("mouseleave", () => {
              image.parentElement.classList.remove("remove-animation")
            })
          }
          $(item).append(image)
          image.addEventListener('click', (e) => {
            //  store.dispatch("ratingModel", {
            //   marked_text: parentImg.src,
            //   csspath: getElementsXpath(parentImg),
            //   object_id: "",
            //   ratingModel: false,
            //   is_image:true
            // });
            e.stopPropagation()
            e.preventDefault()
            let imgSrc = item.tagName === 'PICTURE' ? item.children[2].src.substr(item.children[2].src.indexOf('://') + 3) : item.src.substr(item.src.indexOf('://') + 3)
            let imgSrcArray = imgSrc.split('/')
            let imgName = imgSrcArray.slice(-1)[0]
            let imageId = (imgName + '-' + window.location.href.substr(window.location.href.indexOf('://') + 3)).replace(/\//g, "-").replace(/\s+/g, '-').replace(/%/g, '-')
            if (imageId.endsWith('#')) {
              imageId = imageId.slice(0, -1)
            }
            store.dispatch('getSpecificRatedObject', {
              is_image: true,
              id: imageId,
              css_path: cssPath,
              media_url: item.tagName === 'PICTURE' ? item.children[2].src : item.src
            });
          })
        }
      })
      document.querySelectorAll("video:not([alt='data-rate-media']").forEach((item) => {
        if (!item.parentElement.querySelector(".data-rate-media")) {
          item.classList.add('data-rate-media')
          var image = document.createElement('img');
          image.src = `${base_url_media}stars.svg`;
          image.style.cursor = 'pointer';
          image.style.position = 'absolute';
          image.style.top = "0px";
          image.style.left = "0px";
          image.alt = "data-rate-media"
          image.classList.add('data-rate-media')
          image.style.zIndex = "99999999"
          if (isExtension) {
            image.style.opacity = 0
            item.onmouseleave = () => {
              image.style.opacity = 0
            }
            item.onmouseenter = () => {
              image.style.opacity = 1
            }
            image.onmouseenter = () => {
              image.style.opacity = 1

            }
            image.onmouseleave = () => {
              image.style.opacity = 0

            }

          }

          $(item).append(image)
          if (image?.parentElement?.parentElement?.parentElement?.querySelectorAll(".data-rate-media")?.length > 0 && image.parentElement.parentElement.parentElement.querySelectorAll(".data-rate-media")[2]) {
            image.parentElement.parentElement.parentElement.querySelectorAll(".data-rate-media")[2].remove()
          }
          image.addEventListener("mouseenter", () => {
            if (image.parentElement.parentElement) {
              image.parentElement.parentElement.classList.add("remove-animation")
            }
          })
          image.addEventListener("mouseleave", () => {
            if (image.parentElement.parentElement) {
              image.parentElement.parentElement.classList.remove("remove-animation")
            }
          })
          image.addEventListener('click', (e) => {
            e.stopPropagation()
            e.preventDefault()
            let videoSrc = $(item).attr("poster") ? $(item).attr("poster") : $(item).attr("src")
            let videoSrcArray = videoSrc.split('/')
            let videoName = videoSrcArray.slice(-1)[0]
            let videoId = (videoName.substr(videoSrc.indexOf('://') + 3) + '-' + window.location.href.substr(window.location.href.indexOf('://') + 3)).replace(/\//g, "-").replace(/\s+/g, '-').replace(/%/g, '-')
            let idExist = item.parentElement.id
            if (videoId.endsWith('#')) {
              videoId = videoId.slice(0, -1)
            }
            if (idExist) {
              videoId = idExist
            }
            store.dispatch('getSpecificRatedObject', {
              is_video: true,
              id: videoId,
              css_path: getElementsXpath(item),
              media_url: videoSrc
            });
          })
        }
      })
    },
    pageRateOpen() {
      console.log("page rate open");

      this.$store.state.ratedObject.model = false;
      this.$store.state.ratedObject.pageRateMode = true;
      this.$store.state.rate_type = 'full'
      // showProfile = true;
    },
    getXPath(element) {
      var xpath = "";
      for (; element && element.nodeType == 1; element = element.parentNode) {
        var id =
            $(element.parentNode).children(element.tagName).index(element) + 1;
        id > 1 ? (id = "[" + id + "]") : (id = "[1]");
        xpath = "/" + element.tagName.toLowerCase() + id + xpath;
      }
      return xpath;
    },
    checkLength(context, css_path) {
      if (context.length > css_path.innerText.length) {
        return css_path.parentElement;
      } else {
        return css_path;
      }
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    getData() {
      this.$store.state.productEnabledFeatures.showPopup &&
      HTTP.get("/popups")
          .then((res) => {
            let data = res.data.popup;
            if (data && (data.dialog_id === 1 || data.dialog_id === 2)) {
              this.$store.state.remarks.model = true;
              setTimeout(() => {
                this.$store.state.remarks.model = false;
              }, 10000);
              if (data.dialog_id === 1) {
                this.$store.state.remarks.imgSrc =
                    base_url_media + "avatar2.svg";
                this.$store.state.remarks.htmlPattern = `<h3 class="heading">${data.args[0]}</h3>`;
              } else {
                this.$store.state.remarks.imgSrc =
                    base_url_media + "avatar3.svg";
                this.$store.state.remarks.htmlPattern = `<p class="text-left margin-bottom">${data.args[0]}</p><p class="text-green text-left">${data.args[1]}</p><div class="d-flex text-left margin-top-24"><img class="margin-top-neg-14" src="${base_url_media}star.svg" /> <h3 class="heading margin-left-14">${data.args[2]}</h3></div>`;
              }
            } else {
              this.$store.state.remarks.model = false;
            }
          })
          .catch((error) => {
            if (error.response.data.status == 401) {
              localStorage.removeItem("user_datarate");
              // window.location.reload();
            } else {
              showErrorMessage(this.$store, error);
            }
          });
    },
    setPopup1() {
      this.$store.state.remarks.model = true;
      this.$store.state.remarks.imgSrc = base_url_media + "avatar1.svg";
      this.$store.state.remarks.htmlPattern =
          '<h3 class="heading">Thanks for your rating! You make web search experience better.</h3> <p class="paragraph-text">You received <span class="text-green">20</span> points for the rating!</p>';
    },
    setPopup2() {
      this.$store.state.remarks.model = true;
      this.$store.state.remarks.imgSrc = base_url_media + "avatar2.svg";
      this.$store.state.remarks.htmlPattern =
          '<h3 class="heading">Mark and rate what is most relevant to you and get points.</h3>';
    },
    setPopup3() {
      this.$store.state.remarks.model = true;
      this.$store.state.remarks.imgSrc = base_url_media + "avatar3.svg";
      this.$store.state.remarks.htmlPattern = `<p class="text-left margin-bottom">Hi, what text on this page answers this question?</p><p class="text-green text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor?</p><div class="d-flex text-left margin-top-24"><img class="margin-top-neg-14" src="${base_url_media}star.svg" /> <h3 class="heading margin-left-14">Mark and rate the most appropriate answer in the text and get points!</h3></div>`;
    },
    powerOff: function () {
      $(".data-rate-dot").css("display", "none");

      this.$store.state.disableModel = false;
    },
    stars: function (markedText, css_path) {
      console.log(css_path)
      $(".data-rate-dot").css("display", "none");
      var path = this.getXPath(
          this.checkLength(
              markedText,
              window.getSelection().anchorNode.parentElement
          )
      );

      store.dispatch("ratingModel", {
        marked_text: markedText,
        csspath: path,
        object_id: "",
        ratingModel: false,
      });
    },
    warning: function (even) {
      $(".data-rate-dot").css("display", "none");

      console.log(even);
    },
    DraggedPosition: function (eve) {
      console.log(eve, "herer");
    },
  },
};
</script>

<style>
.datarate_modal {
  z-index: 1;
  margin-left: auto !important;
  right: 0 !important;
  z-index: 999999999 !important;
}

.star-datarate-btn {
  position: fixed;
  right: 0 !important;
  top: 10px !important;
  padding: 0 !important;
  min-width: 50px !important;
  border-radius: 8px 0 0 8px !important;
  box-shadow: unset !important;
  justify-content: flex-start !important;
  padding-left: 8px !important;
  z-index: 99999999 !important;
}

/* .feedback-datarate-btn {
  position: fixed;
  bottom: 50px !important;
  padding: 10px !important;
  min-width: 50px !important;
  border-radius: 8px !important;
  box-shadow: unset !important;
  justify-content: flex-start !important;
  z-index: 99999999 !important;
  width: fit-content;
  right: 0 !important;
  left:100%;
  transform-origin: 0% 100%;
  -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg) translateX(50%);
} */

.hideFeedbackButton {
  opacity: 0 !important;
  pointer-events: none !important;
}
.feedback-datarate-btn{
  z-index: 10000;
  position: fixed;
  width: fit-content;
  left: 100%;
  bottom: 20%;
  background-color: #0db7c4 !important;
  border-color: #0db7c4 !important;
  padding: 10px;
  font-size: 0.875rem;
  border-radius: 8px !important;
  color:white !important;
  cursor: pointer;
  transform-origin: 0% 100%;
  -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg) translateX(-50%);
}
.feedback-datarate-btn:focus {
    opacity: 0.24;
}

.feedback-datarate-btn:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.star-datarate-btn .v-icon {
  font-size: 20px !important;
}

.search-datarate-btn {
  position: fixed;
  right: 0 !important;
  top: 10px !important;
  margin-top: 40px !important;
  padding: 0 !important;
  min-width: 30px !important;
  border-radius: 8px 0 0 8px !important;
  box-shadow: unset !important;
  justify-content: flex-end !important;
  padding-left: 8px !important;
  z-index: 99999999 !important;
}

.search-datarate-btn .v-icon {
  font-size: 20px !important;
}

.data-rate-hide {
  display: none;
}

.v-application .primary {
  background-color: #128d6f !important;
  border-color: #128d6f !important;
}

.v-application {
  height: 0px !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1) !important;
}

.slide-fade-enter,
.slide-fade-leave-to

  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateX(10px);
  opacity: 0;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left !important;
}

.data-rate-dot .data-rate-draggable {
  visibility: hidden;
}

.data-rate-dot:hover .data-rate-draggable {
  visibility: visible;
}

.v-application--wrap {
  min-height: 0px !important;
  height: 0px !important;
}

.-wrap {
  height: 0px !important;
  height: 0px;
}

.position-relative {
  position: relative !important;
}

.v-alert {
  position: fixed;
  right: 0 !important;
  top: 0 !important;
  z-index: 999999999 !important;
}

mark {
  background: lightgreen !important;
}

.f-16 {
  font-size: 16px !important;
}

.f-14 {
  font-size: 14px !important;
}

.remove-animation {
  transform: none !important
}

.width-max-content {
  width: max-content !important;
}

.item-hover-flex-container {
  z-index: -10 !important;
}

</style>
