import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

const vuetify = new Vuetify({
    theme: {
      themes: {
        light: {
          primary: '#00ca99',
          secondary: '#6692FF',
          anchor: '#8c9eff',
          border: '#fff',
          profile: '#8080806b',
          stack: '#0000003b',
        },
      },
    },
    icons: {
      iconfont: 'mdi', // default - only for display purposes
    },
  })
  
  export default vuetify