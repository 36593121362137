<template>
  <div>
  <div class="" >
    <div class="comments_section" >
      <div class="">
        <div class="user_profile">
          <div class="user_name d-flex justify-space-between">
            <div class="" style="bottom: 20px !important;">
              <img
                  :src="commentData.image_path ? commentData.image_path : media_url + imagesOptions[0]"
                  @error="imageLoadError" class="rounded-circle" alt="data-rate-media" height="46" width="46">
              <div class="">
                <h4 class="ml-1 mb-0 pb-0 text-start">{{ commentData.user_name }}</h4>
                <div class="user_stars d-flex align-center">
                  <v-rating
                      color="yellow darken-3"
                      :value="commentData.stars"
                      background-color="blue-grey lighten-5"
                      empty-icon="$ratingFull"
                      large
                      readonly
                  ></v-rating>
                </div>
              </div>
            </div>
            <div class="user_date" :class="this.$vuetify.rtl ? 'flex-row-reverse':''">{{ timeSince(commentData.create_time) }} <span class="ml-1"> {{$t("message.Ago")}}</span></div>
          </div>
        </div>
        <div class="mt-5">
          <v-chip class="mr-2 mb-2" v-for="(chip, index) in commentData.tags" :key="index">
            {{ chip }}
          </v-chip>
        </div>
        <div class="user_desc" v-if=" commentData.text">
          <p>
            {{ commentData.text }}
          </p>
        </div>
        <div class="text-right cursor-pointer comments_length" @click="showrateComments(commentData.id)">
          <p class="mb-0">{{ commentData.comment_count }} {{$t("message.commentsText")}}</p>
        </div>
      </div>
    </div>
    <div v-if="showComments">
    <rate-comments :ratingComments="ratingComments"
                    :currentLang="$store.state.currentLang"
                   v-if="ratingComments.length>0" />
    <div class="d-flex ml-8 mt-3">
      <img
                  :src="user.profile_image_url?user.profile_image_url:'https://d2kzr9hca36gxq.cloudfront.net/placeholder_female.png'"
                  @error="imageLoadError" class="rounded-circle mr-2 mt-3" alt="data-rate-media" height="30" width="30">
    <v-text-field
            :label="$t('message.addCommentLabel')"
            class="primary--text text-field-2" v-if="showComments"
            append-icon="mdi-send"
            v-model="userComment"
            filled
            rounded
            dense
            @click:append="addComment(commentData.id)"
            ></v-text-field>
          </div>
            </div>
  </div>
</div>
</template>

<script>
import {base_url_media,showErrorMessage} from "../../../public/helper"
import rateComments from "./rateComments.vue"
import HTTP from '../../services/api';
export default {
  name: "comments",
  props: ["commentData"],
  components:{rateComments},
  data() {
    return {
      media_url: base_url_media,
      // imagesOptions: ['placeholder_batman.png', 'placeholder_female.png', 'red_head_avatar.png', 'avatar_female.png'],
      imagesOptions: ['red_head_avatar.png'],
      showComments:false,
      userComment:"",
      ratingComments:[],
      comment:"",
      user:this.$store.state.user,
    }
  },
  methods: {
    imageLoadError(e) {
      e.target.src = base_url_media + "placeholder.png"
    },
    addComment(id){
      HTTP.post(
          `/ratings/${id}/comments`,
          {content:this.userComment}
        )
          .then((response) => {
            this.showrateComments(id)
            this.ratingComments = response.data.comments
            this.userComment = ''
          })
          .catch((error) => {
            // error = Error object,
            if (error.response.data.status == 401) {
              localStorage.removeItem("user_datarate");
              // window.location.reload();
            }
            showErrorMessage(this.$store, error)
          });
    },
    getDuration: function (seconds) {
      var DURATION_IN_SECONDS = {
        epochs: ['year', 'month', 'day', 'hour', 'minute'],
        year: 31536000,
        month: 2592000,
        day: 86400,
        hour: 3600,
        minute: 60
      };
      var epoch, interval;

      for (var i = 0; i < DURATION_IN_SECONDS.epochs.length; i++) {
        epoch = DURATION_IN_SECONDS.epochs[i];
        interval = Math.floor(seconds / DURATION_IN_SECONDS[epoch]);
        if (interval >= 1) {
          return {
            interval: interval,
            epoch: this.$vuetify.rtl  ? this.getHebrewDate(epoch, interval) : epoch
          };
        }
      }

    },
    getHebrewDate(time, interval){
      if (time === 'year') {
        if(interval > 1) return 'שנים'
        return 'שָׁנָה'
      }
      else if (time === 'month') {
        if(interval > 1) return 'חודשים'
        return 'חוֹדֶשׁ'
      }
      else if (time === 'day') {
        if(interval > 1) return 'ימים'
        return 'יְוֹם'
      }
      else if (time === 'hour') {
        if(interval > 1) return 'שעות'
        return 'שָׁעָה'
      }
      else if (time === 'minute') {
        if(interval > 1) return 'דקות'
        return 'דַקָה'
      }
    },
    showrateComments(id){
      this.showComments = true
      HTTP.get(
          `/ratings/${id}/comments`
        )
          .then((response) => {
            this.ratingComments = response.data.comments

          })
          .catch((error) => {
            // error = Error object,
            if (error.response.data.status == 401) {
              localStorage.removeItem("user_datarate");
              // window.location.reload();
            }
            showErrorMessage(this.$store, error)
          });
    },
    timeSince: function (date) {
      var seconds = Math.floor((new Date() - new Date(date)) / 1000);
      var duration = this.getDuration(seconds);
      var suffix = ((duration.interval > 1 || duration.interval === 0) && this.$store.state.currentLang === 'en') ? 's' : '';
      return duration.interval + ' ' + duration.epoch + suffix;
    }
  },
}
</script>

<style>
.comments_section {
  border: 1px solid rgba(0, 0, 0, 0.12) !important;
  border-radius: 10px !important;
  padding: 14px !important;
  margin-bottom: 8px !important;
}

.comments_section .user_profile .user_name > div > img {
  margin-right: 14px !important;
}

.comments_section .user_profile .user_name h4 {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #262626 !important;
}

.comments_section .user_profile .user_date, .comments_length {
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 24px !important;
  color: rgba(38, 38, 38, 0.5) !important;
}

.comments_section .user_profile .user_dots {
  margin-left: 10px !important;
}

.comments_section .user_desc,.user_comments {
  margin-top: 15px !important;
}

.v-application .yellow--text.text--darken-3 {
  color: #FFCC48 !important;
  caret-color: #FFCC48 !important;
}

.comments_section .user_desc p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 140% !important;
  color: rgba(38, 38, 38, 0.87) !important;
  margin-bottom: 21px !important;
}
.comments_section .user_comments p {
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 140% !important;
  color: rgba(38, 38, 38, 0.87) !important;
}

.comments_section .user_comment p {
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 16px !important;
  color: #128D6F !important;
  opacity: 0.7 !important;
  margin-bottom: 0 !important;
}

.comments_section .inner_user_comment {
  border-left: 2px solid #F3F3F3 !important;
  padding-left: 17px !important;
}

.user_stars .v-rating button {
  font-size: 18px !important;
  padding: 0 !important;
}

.user_stars img {
  margin-right: 6px !important;
}

.user_stars img:last-child {
  margin-right: 0 !important;
}

.user_name > div {
  display: flex !important;
  align-items: center !important;
}
/*
.dara-rate-property .v-text-field .v-label {
  top: 0 !important;
} */
.v-text-field--outlined > .v-input__control > .v-input__slot{
  align-items: center !important;
}
.v-text-field--enclosed.v-input--dense:not(.v-text-field--solo) .v-input__append-inner{
  margin-top: 8px !important;
}
.text-field-2 input{
  padding: 4px 0 2px !important
}
</style>
