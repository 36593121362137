<template>
  <div>
    <div style="margin-top: 1em;overflow: hidden">
      <v-chip-group column multiple>
        <inner-tags class="mr-2 mb-2" v-for="(tag,index) in evaluateTags.filter((tag)=>tag!==true)" :key="index"
                    :chip-data="tag"></inner-tags>
      </v-chip-group>
      <a text class="rated-object mb-0 show-more-tags" @click="tagsCount +=5"
         v-if="$store.state.ratedObject.pageRateResponse.tags && $store.state.ratedObject.pageRateResponse.tags.length > 5 && tagsCount < $store.state.ratedObject.pageRateResponse.tags.length">+5</a>
    </div>

    <span v-if="$store.state.ratedObject.pageRateResponse.tags && $store.state.ratedObject.pageRateResponse.tags.length===0">
  {{ $t('message.addFirstTag') }}
  </span>
  </div>
</template>

<script>
import innerTags from "./innerTags.vue"

export default {
  components: {
    innerTags
  },
  data() {
    return {
      tagsCount: 5,
    }
  },
  methods: {
    displayAllTags() {
      this.$store.state.ratedObject.pageRateResponse = false;
    }
  },
  computed: {
    evaluateTags() {
      return this.$store.state.ratedObject.pageRateResponse?.tags? this.$store.state.ratedObject.pageRateResponse.tags.slice(0, this.tagsCount):[]
    }
  },
}
</script>

<style scoped>
.data-rated-suggested-tags {
  /*border: 0.01em solid rgba(0,0,0,0.3);*/
  /*border-radius: 25px;*/
  /*margin: 1%;*/
  /*padding: 2%;*/
  /*cursor: pointer;*/
  border-radius: 25px !important;
  margin: 1% !important;
  padding: 6px 12px !important;
  cursor: pointer !important;
  background: #F3F3F3 !important;
  color: #262626 !important;
}

.show-more-tags {
  color: rgba(0, 0, 0, .5) !important;
}
</style>
