import Mark from "mark.js/dist/mark.es6";
import $ from "jquery"
import i18n from "../src/plugins/vue-i18n";

export default function getElementByXpath(path) {
  if(!path)
  {
    return null
  }
  return document.evaluate(
    path,
    document,
    null,
    XPathResult.FIRST_ORDERED_NODE_TYPE,
    null
  ).singleNodeValue;
}

export const  getElementsXpath = ( element ) =>
{
    var xpath = '';
    for ( ; element && element.nodeType == 1; element = element.parentNode )
    {
        var id = $(element.parentNode).children(element.tagName).index(element) + 1;
        id > 1 ? (id = '[' + id + ']') : (id = '');
        xpath = '/' + element.tagName.toLowerCase() + id + xpath;
    }
    return xpath;
}
export const markBlue = (css_path, content) => {
  var context = getElementByXpath(css_path);
  var instance = new Mark(context);
  instance.mark(content.replace(/\n/g, ""), {
    accuracy: "partially",
    acrossElements: true,
    separateWordSearch: false,
    diacritics: false,
  });
};
export const unmarkBlue = (css_path, content) => {
  if (css_path !== "") {
    var context = getElementByXpath(css_path);
    var instance = new Mark(context);
    instance.unmark(content.replace(/\n/g, ""), {
      accuracy: "partially",
      acrossElements: true,
      separateWordSearch: false,
      diacritics: false,
    });
  }
};

export const showErrorMessage = (store, error) => {
  store.state.errorMessage = error.response.data.error
    ? error.response.data.error
    : i18n.t("message.wrongMessage")
  store.state.errorMessagePopUp = true;
  setTimeout(function () {
    store.state.errorMessagePopUp = false;
  }, 3000);
};

export const showStarSlider = 10;
export const allowPagination = false;

export const base_url_media = "https://d2kzr9hca36gxq.cloudfront.net/";

export function hasSubArray(master, sub) {
  return sub.every((i => v => i = master.indexOf(v, i) + 1)(0));
}

export const showDefaultComments = 2
