<template>
  <div>
    <div class="d-flex comment-datarate-section" v-on:click="openComment" v-if="isCommentIcon">
    <img alt="data-rate-media" :src="media_url + 'chat.png'" :class="this.$vuetify.rtl ? 'ml-2': 'mr-2'" style="height: 18px;" />
      <h4 style="color:#5fb19b; font-size:16px">{{$t('message.addCommentLabel')}}</h4>
      </div>
    <input class="data-rate-input dara-rate-property f-14" type="text" v-model="addingComment"
           v-else :placeholder="$t('message.addComment')">
  </div>
</template>

<script>
import {base_url_media} from "../../../public/helper"
export default {
  name: 'addingComment',
  components:{
  },
  data: function () {
    return {
      commentIcon:true,
      comment:false,
      media_url:base_url_media
     // addingComment:'',

    }
  },
  methods: {
    openComment: function () {
      this.$store.state.rating.commentIcon=false;
    },


  },
  computed:{
    isCommentIcon:function (){
      return this.$store.state.rating.commentIcon
    },
    addingComment: {
      get () {
        return this.$store.state.rating.comment
      },
      set (value) {
        this.$store.commit('addComment', value)
      }
    }

  }



}
</script>
<style scoped>
.comment-datarate-section{
  align-items: center;
  cursor: pointer;
}
</style>
<style>
.data-rate-input{
  width: 100% !important;
  border: none !important;
  font-size: 0.9em !important;
  /*height: 50px;*/
  box-sizing: border-box;
  padding: 0 10px !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
  outline: none !important;
  line-height: 50px !important;
  background: none !important;
}
</style>

