import axios from "axios";
let HTTP = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: {
        "api-key": document.getElementsByName('datarate-api-key').length?document.getElementsByName('datarate-api-key')[0].dataset.apiKey:'',
        "datarate-plugin-source": (document.getElementsByName('datarate-plugin-source') && document.getElementsByName('datarate-plugin-source')[0]) ? document.getElementsByName('datarate-plugin-source')[0].dataset.source : ''
    },
})

HTTP.interceptors.request.use(function (config) {
    let token = localStorage.getItem("user_datarate") ? localStorage.getItem("user_datarate") : ''
    config.headers["Authorization"] = token ?  "Bearer " + token: '';
    return config;
  });

export default HTTP