import Vue from "vue";
import App from "./App.vue";
import store from "../store";
import movable from "v-movable";
import axios from "axios";
import VueAxios from "vue-axios";
import vuetify from "./plugins/vuetify";
import i18n from './plugins/vue-i18n'

Vue.use(movable);
Vue.use(VueAxios, axios);
Vue.config.productionTip = false;

// Create VueI18n instance with options

// Now the app has started!
new Vue({
  i18n,
  vuetify,
  store: store,
  render: (h) => h(App),
  mounted() {
    window.addEventListener('hashchange', this.handleHashChange);
  },
  methods: {
    handleHashChange() {
      const newURL = window.location.href;
      let lastUrls = []
      if (localStorage.getItem("lastUrls")) {
        lastUrls = JSON.parse(localStorage.getItem("lastUrls"))
        if (lastUrls.length === 10) {
          lastUrls.shift()
        }
      }
      lastUrls.push(newURL)
      localStorage.setItem("lastUrls", JSON.stringify(lastUrls))
      // Perform actions based on the new URL
    },
  },
}).$mount("#datarate");
