<template>
    <div class="login_form">
        <div class="text-right mt-3">
            <a href="https://app.datarate.io/login" target="_blank">Don't have an account</a>
        </div>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-alert dense text type="error" class="my-2" v-if="error">
                Invalid Credentials
            </v-alert>
            <v-text-field label="Email" v-model="email" required :rules="emailRules"></v-text-field>
            <v-text-field label="Password" v-model="password" :rules="passwordRules" type="password"></v-text-field>
            <v-btn rounded color="primary" large class="btn-datarate-green w-100" @click="validate"> Login</v-btn>

            <div class="text-center">
                <a href="" type="button" class="mb-1 cant_sign">Can't Sign In?</a>
                <p>OR SIGN IN WITH</p>

                <div class="login_btns mt-4">
                    <v-btn class="login_via fb-login" href="https://app.datarate.io/login?is_extension=true" target="blank">
                        <img :src="media_url + 'facebook.svg'" alt="">
                        <p>FACEBOOK</p>
                    </v-btn>
                    <v-btn class="login_via google-login" href="https://app.datarate.io/login?is_extension=true" target="blank">
                        <img :src="media_url + 'google.svg'" alt="">
                        <p>GOOGLE</p>
                    </v-btn>
                    <v-btn class="login_via apple-login" href="https://app.datarate.io/login?is_extension=true" target="blank">
                        <img :src="media_url + 'apple.svg'" alt="">
                        <p>APPLE</p>
                    </v-btn>
                </div>
                <div class="terms mt-3">
                    <a href="" type="button">Terms and Conditions</a><span> . </span>
                    <a href="" type="button"> Privacy Policy</a>
                </div>
            </div>
        </v-form>
    </div>
</template>

<script>
import HTTP from '../../services/api';
import {showErrorMessage,base_url_media} from '../../../public/helper'
export default {
    name: "LoginComponent",
    components: {},
    data: function () {
        return {
            media_url:base_url_media,
            valid: true,
            email: '',
            password: '',
            error: false,
            passwordRules: [
                v => !!v || 'Password is required',
                v => (v && v.length >= 8) || 'Password must be greater than 8  characters',
            ],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
        };
    },
    methods: {
        testingFunction(){
            console.log('')
        },
        validate() {
            this.$refs.form.validate()
            if (this.valid) {
                HTTP.post('/security/login', {
                    email: this.email,
                    password: this.password
                }).then((response) => {
                    this.$store.state.user = response.data.login_response.token;
                    localStorage.setItem("user_datarate", response.data.login_response.token)
                    this.error = false
                    this.$emit("show-search-form",false)

                }).catch((err) => {
                    showErrorMessage(this.$store, err)
                })
            }
        },

    },

};
</script>

<style>
.btn-datarate-green {
    margin-bottom: 1rem !important;
}
.w-100{
    width: 100% !important;
}
.v-application a {
    color: #00ca99 !important
}
.login_btns{
    display: flex !important;
    justify-content: space-between !important;
    align-items: center !important;
}
.login_via{
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border: 1px solid #80808038 !important;
    border-radius: 50px !important;
    width: 110px !important;
    min-height: 50px !important;
}
.login_via img{
    margin-right: 6px !important;
    width: 28px !important;
}
.login_via p{
    font-size: 11px !important;
    font-weight: 500 !important;
    margin-bottom: 0 !important;
    color: #000 !important;
}
.cant_sign{
    font-size: 14px !important;
    font-weight: 600 !important;
    color: #7b6f6f !important;
    margin-bottom: 12px !important;
}
.cant_sign ~ p{
    font-size: 12px !important;
    font-weight: 600 !important;
    color: #7b6f6f !important;
}
.terms{
    text-align: start !important;
}
.terms a{
    font-size: 14px !important;
    font-weight: 500 !important;
    color: #7b6f6f !important;
}
.terms span{
    color: #7b6f6f !important;
    border-radius: 50% !important;
    font-size: 28px !important;
    position: relative !important;
    top: -4px !important;
    left: 1px !important;
}
.login_form .v-btn{
    background-color: transparent !important;
    box-shadow: none !important;
}
</style>
