<template>
    <div class="datarate_modal rate-font-family modal-search">
        <!-- Modal content -->
        <div class="modal-content new-popup">
            <div class="modal-heading b-none">
                <div>
                    <span :class="this.$vuetify.rtl ? 'datarate_close-he' : 'datarate_close'" @click="$store.state.info.model = false">&times;</span>
                </div>
            </div>
            <!-- <stars></stars> -->
            <div class="main-content text-center">
                <img class="margin-bottom" :src="media_url + 'avatar1.svg'" alt="data-rate-media" />
            <div class="f-16">
              {{ $t("message.infoWelcome") }}
              <br/>
              {{ $t("message.infoDesc") }}
            </div>
            </div>
            <div class="modal-footer b-none">
            </div>
        </div>
    </div>
    </template>

    <script>
    import {
  base_url_media,
} from "../../../public/helper";
    export default {
        name: "Info",
        props:{
          currentLang: {
      type: String,
      default: "en",
    }
        },
        data: function () {
    return {
      media_url: base_url_media,
    };
  },
    };
    </script>
